import { styled } from 'styled-components';
import NavLinkItem from '@components/NavLinkItem';

// ================================================================================
// styled
const Body = styled.div`
  background-color: white;
  min-height: 100%; text-align: center;
`;

// ================================================================================

// ================================================================================

function LoginPage() {

  return (
        <Body>
          Sever Error Page! :(
          <NavLinkItem to="/" child={'Login!'}></NavLinkItem>
        </Body>
  );
}

export default LoginPage;
