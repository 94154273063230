import { atom } from 'recoil';
import { AnimType, ModalType, IPhotoDetectCropData, IUserData } from './types/Types';

// ================================================================================
// global UI control Atoms
export const pageAnim = atom<AnimType>({
  key: 'pageAnim',
  default: 'push',
});
export const modal = atom<{isShow:boolean, target:ModalType}>({
  key: 'modal',
  default: {isShow:false, target:'crop'},
});
export const isGlobalLoading = atom<boolean>({
  key: 'isGlobalLoading',
  default: false,
});

export const alertModal = atom<{isShow:boolean, compFn?:Function, cancleFn?:Function, text?:JSX.Element, compText?:string, icon?:'success'|'fail'}>({
  key: 'alertModal',
  default: {isShow:false},
});

// ================================================================================
// global Data Atoms
// export const isLoginUser = atom<boolean>({
//   key: 'isLoginUser',
//   default: false,
// });

export const makeSteps = atom<Number>({
  key: 'makeSteps',
  default: 1,
});


// ================================================================================
// global Data Atoms
export const userData = atom<IUserData>({
  key: 'userData',
  default: {is_login:false, nickname:'', ticket_cnt:0, files:[], isDetailInit:false, role:''},
});

export const originImage = atom<string>({
  key: 'originImage',
  default: '',
});

export const makeProjectData = atom<IPhotoDetectCropData>({
  key: 'makeProjectData',
  default: {url:'', data:[], size:{width:0, height:0,x:0,y:0}, id:'', resultImageURL:''},
});

// export const resultImageURL = atom<string>({
//   key: 'resultImageURL',
//   default: '',
// });
