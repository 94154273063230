import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { styled } from 'styled-components';

import { myInfo, signOut } from '@/apis/users';
import { alertModal, isGlobalLoading, userData } from '@/atoms';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { Body, FooterBtnWrapper, GreenButton, GreenText, RedText, Spinners } from '@/styles/main';
import { IUserData } from '@/types/Types';
import saveAs from 'file-saver';
import { dataURLtoFile } from '@/libs/Utils';
import { motion } from 'framer-motion';
import { IS_DEVICE } from '@/libs/Utils';

// ================================================================================
// styled
// ================================================================================
const Info = styled.p`
  margin-bottom: 90px;
`;

const Hint = styled.a`
  font-size: 20px; color: #6F6F6F; line-height: 34px;
`;

const MyMakingList = styled.div`
  border-radius: 20px; border:3px solid #000; background-color: #f8f8f8;
  min-height: 100px; margin: 20px 0; margin-bottom: 100px;
`

const MakingEmptyBox = styled.div`
  display: flex; justify-content: center; align-items: center;
  height: 600px;
  font-size: 30px; font-weight: bold;;
`

const ImageList = styled.div`
  display: flex; flex-wrap: wrap; justify-content: space-between;
  padding: 5px 22px;
  & > a { width:292px; height: 292px; display: inline-block; margin:15px 0;}
  & > a img { width:100%; height:100%; border-radius: 10px;  overflow: hidden;  }
`;

const Loading = styled.div`
  width: 100%; height: 700px; display: flex; justify-content: center; align-items: center;
`

// ================================================================================

function MyPage() {
  const navigate = useNavigate();

  const [isLoadings, setLoading] = useRecoilState(isGlobalLoading);
  const [user, setUser] = useRecoilState(userData);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const {run} = useAsyncCallback(myInfo);
  const {run:signOutRun} = useAsyncCallback(signOut);
  const ticket_length = 5;

  useEffect(() => {
    if(!user.isDetailInit){
      run().then((res:any) => {
        if(res && res.data){
          setUser({...res.data.result as IUserData, is_login:true, isDetailInit:true});
        }else{
          // window.location.replace('/');
        }
        console.log(res);
      });
    }
  }, []);

  const changeBase64ToImage = (baseStr:string) => ('data:image/png;base64,'+baseStr);
  const changeBase64ToShareAction = async (baseStr:string) => {
    const _fileName = 'kissmas_'+(new Date().getTime())+'.png';
    const _fileData = dataURLtoFile('data:image/png;base64,'+baseStr, _fileName);

    if (navigator.share && _fileData && !IS_DEVICE.ANDROID && !IS_DEVICE.DESKTOP) {
      try {
          // Call the share() method to open the native share dialog
          await navigator.share({ files: [_fileData] });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing content:', error);
        }
    }else{
      saveAs('data:image/png;base64,'+baseStr, _fileName);
    }
  }

  const loginDataInit = () => {
    setUser({is_login:false, nickname:'', ticket_cnt:0, files:[], isDetailInit:false, role:''});
    localStorage.removeItem("token");
    localStorage.removeItem('token_provider');
  }

  const logoutHandler = () => {
    loginDataInit();
    
    setAlert({isShow:true, text:<>로그아웃 되었습니다.</>, compFn:()=>{
      navigate('/');
    }});
  };

  const resignClickHandler = () => {
    setAlert({isShow:true, text:<>탈퇴하시는 경우 모든 크리스마쮸가<br/>삭제되며 복구할 수 없습니다.<br/>그래도 탈퇴하시겠습니까?</>, 
    compFn:()=>{
      // const _provider = localStorage.getItem('token_provider');
      
      setLoading(true);
      signOutRun().then((res:any) => {        
        console.log(res);
        setLoading(false);
        navigate('/seeyou');
      });
    }, cancleFn:()=>{},
    compText:'탈퇴하기'});
  }

  return (
    
        <Body>
          {user.isDetailInit?
            <>
              <h2>{user.nickname} 님</h2>
              <Info>
                지금까지 총 {user.files.length}개의 <GreenText>크리스마</GreenText><RedText>쮸</RedText>를 만들었어요<br/>
                앞으로 {(ticket_length - user.ticket_cnt >= 0? ticket_length - user.ticket_cnt : 0)}개의 <GreenText>크리스마</GreenText><RedText>쮸</RedText>를 더 만들 수 있어요
              </Info>

              <h3>{user.nickname} 님이 만든 <GreenText>크리스마</GreenText><RedText>쮸</RedText></h3>
              <Hint>*사진을 클릭하여 다운로드할 수 있습니다<br/>크리스마쮸는 생성 일로부터 30일 경과 시 삭제됩니다</Hint>
            </>:
            <Loading><Spinners/></Loading>
          }
          {user.isDetailInit?
            <MyMakingList>
              {
                user?.files.length === 0 && <MakingEmptyBox>아직 <GreenText>크리스마</GreenText><RedText>쮸</RedText>를 만들지 않았어요!</MakingEmptyBox>
              }
              <ImageList>
                { user?.files.length !== 0 && user?.files.map((file, idx) => {
                  return (<motion.a whileTap={{ scale: 0.95 }} onClick={()=>changeBase64ToShareAction(file.image)} key={idx}><img src={changeBase64ToImage(file.image)} alt={file.id}/></motion.a>)
                })}
              </ImageList>
            </MyMakingList>
          :null}
          {user.isDetailInit?
          <FooterBtnWrapper>
            <GreenButton whileTap={{ scale: 0.9 }} onClick={logoutHandler}>로그아웃</GreenButton>
            <a onClick={resignClickHandler} style={{textDecoration:'underline'}}>탈퇴하기</a>
          </FooterBtnWrapper>
          :null}
        </Body>
  );
}

export default MyPage;
