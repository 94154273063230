
import { SERVICE_DATA, SITE_LANG } from '@/Data';
import { styled } from 'styled-components';

// ================================================================================
// styled

// ================================================================================
const FooterWrapper = styled.footer`
    height: 250px; border-top: 10px solid #006F57; padding: 50px; background-color: white;
    position: relative; width: 100%; box-sizing: border-box;
    &>p {font-size: 20px !important; margin-bottom:54px; margin-top:13px; font-weight:400;}
`;

const Btns = styled.div<{$isKr:Boolean}>`
  img { 
    width:159px; 
    position:absolute; 
    right:30px; 
    bottom: ${({$isKr}) => $isKr?'74px':'45px'};
  }
  & > a { font-size:20px; font-weight:bold; margin-bottom: 10px;
    display: ${({$isKr}) => $isKr?'inline-block':'block'};
    text-decoration:none;
  }
  & > a svg { margin-left: 5px; position: relative; top: 0px; }
  & > a:first-child { margin-right: 75px; }
`;

// ================================================================================

// function NavLinkItem(props:NavItemProps) {
function Footer() {
  
  return (
    <FooterWrapper>
      <p>Copyright 2023 dob Studio. All Rights Reserved</p>
      <Btns $isKr={SITE_LANG === 'kr'}>
        <a href={SERVICE_DATA.TERMS_OF_SERVICE_URL} target='_blank' rel='noopener'>
            {SITE_LANG === 'kr'? '이용약관':'Terms and Conditions of Service'}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.20857 12L-5.28283e-08 10.7914L9.07714 1.71429L3.42857 1.71429L3.42857 8.03807e-07L12 4.29138e-07L12 8.57143L10.2857 8.57143L10.2857 2.92286L1.20857 12Z" fill="black"/>
            </svg>
        </a>
        <a href={SERVICE_DATA.PRIVACY_POLICY_URL} target='_blank' rel='noopener'>
        {SITE_LANG === 'kr'? '개인정보 처리방침':'Privacy Policy'}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.20857 12L-5.28283e-08 10.7914L9.07714 1.71429L3.42857 1.71429L3.42857 8.03807e-07L12 4.29138e-07L12 8.57143L10.2857 8.57143L10.2857 2.92286L1.20857 12Z" fill="black"/>
            </svg>
        </a>
        <a href={SERVICE_DATA.DOB_URL} target='_blank' rel='noopener'>
          <img src="https://s3.ap-northeast-2.amazonaws.com/test.dob.world/kissmas/dob-footer-logo.png" alt="logo"/>
        </a>
      </Btns>
    </FooterWrapper>
  );
}

export default Footer;