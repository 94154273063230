import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
// import "./styles.css";

import { originImage,makeProjectData, isGlobalLoading } from "@/atoms";
import getCroppedImg from "@hooks/cropImage";
import { FooterBtnWrapper, GreenButton, GreenLineButton, GreenText, RedText } from "@/styles/main";
import { useAsyncCallback } from "@hooks/extended-axios/hooks";
import { fileUpload } from "@/apis/process";
import { IBboxType, ICropResultData } from "@/types/Types";
import { IS_LOCAL_TEST, SITE_LANG } from "@/Data";


// ================================================================================
// styled

const ModalContents = styled.div`
    h2 { font-size:30px;}
    & > span { 
        display: flex; margin-bottom:20px; justify-content: space-between;
        
        input[type='text'],select { width:100%; padding:20px; font-size:25px; box-sizing:border-box; text-indent:20px; }
    }
    display: flex; flex-direction: column; justify-content: center; align-items: center;
`;

const CropContainer = styled.div` width: 750px; position: relative; height: 750px; background-color:black; `;

const Controls = styled.div<{$isMobile:Boolean}>`
  width: 600px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({$isMobile})=> $isMobile? `display:none;` : ''}

  /* background: linear-gradient(to right, #006F57 0%, #006F57 50%, #BCBCBC 50%, #BCBCBC 100%); */
  outline: none;
  transition: #BCBCBC 450ms ease-in;
  -webkit-appearance: none;
  accent-color: #006F57;

  p { margin:0; width:100%; text-align:left; font-size:25px;}
`;

const Slider = styled.input` width: 100%; padding: 18px 0px; margin-bottom: 20px; `;

const FooterBtnRelativeWrapper = styled(FooterBtnWrapper)`
  position: relative !important; bottom: 0; margin-top: 50px; display: flex; justify-content: space-between; width: 662px;
`;

const Guide = styled.div`
  padding: 0 16px; box-sizing: border-box; width: 662px;
  & > * { margin:0; }
  & > p { font-size:22px; margin-top:10px;}
`

const EnGreenLineButton = styled(GreenLineButton)<{$isEn:Boolean}>`
  ${({$isEn})=> $isEn? `padding:30px 5px; font-size:27px;` : ''}
`
const EnGreenButton = styled(GreenButton)<{$isEn:Boolean}>`
${({$isEn})=> $isEn? `padding:30px 5px; font-size:27px;` : ''}
`

// ================================================================================
// function

type CropModalProps = {
  onComped: () => void;
}


function CropModal(props:CropModalProps) {
  const [originImages] = useRecoilState(originImage);
  const [isLoading, setLoading] = useRecoilState(isGlobalLoading);
  const [projectData, setProjectData] = useRecoilState(makeProjectData);
  
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area|null>(null)
  const {run} = useAsyncCallback(fileUpload);
  const cropperRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  },[]);
  const onChangeImage = () => {
    props.onComped();
  }
  const onCropCompleteClickHandler = (croppedArea: Area, croppedAreaPixels: Area) => setCroppedAreaPixels(croppedAreaPixels);
  const onCompEdit = async () => {
    setLoading(true);
    try {
      await getCroppedImg(
        originImages,
        croppedAreaPixels,
        rotate
      ).then((res:any) => {
        fetch(res)
          .then((response) => response.blob())
          .then((blob) => {
            
            const _formData = new FormData();
            _formData.append('files', blob, 'crop_image.png');
            // local test--------------------------------
            if(IS_LOCAL_TEST) {
              setLoading(false);
              setProjectData({url:res, data:[{l:10, t:10, r:100, b:100,id:'10'}], id:'1', size:croppedAreaPixels??{width:0, height:0, x:0, y:0}}  );
              //setProjectData({url:res, data:[], id:'1', size:croppedAreaPixels??{width:0, height:0, x:0, y:0}}  );
              props.onComped();
              return;
            }

            run(_formData).then((_res:any) => {
              try{
                let result:ICropResultData = _res.data.result[0] as ICropResultData;
                const _data:IBboxType[] = [];

                // console.log(result);
                result = {...result, bboxs:JSON.parse(result.bboxs)};
                // console.log(result.bboxs);
                for(let i = 0; result.indexs.length > i; i++) _data.push({...result.bboxs[i], id:result.indexs[i], isSelect:false});

                // console.log(_data);
                setProjectData({url:res, data:_data, id:result.id, size:croppedAreaPixels??{width:0, height:0, x:0, y:0}});
                props.onComped();
              } catch(e){
                
              }
              setLoading(false);
            })
          })
      });

    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ModalContents>
      <CropContainer className="crop-container">
        <Cropper
          image={originImages}
          crop={crop}
          zoom={zoom}
          rotation={rotate}
          ref={cropperRef}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteClickHandler}
          onZoomChange={setZoom}
          onRotationChange={setRotate}
        />
      </CropContainer>
      <br/>
      <Controls $isMobile={isMobile}>
        <p>ZOOM</p>
        <Slider
          type="range"
          value={zoom} min={1} max={3} step={0.1}
          aria-labelledby="Zoom"
          onChange={(e:any) => {
            setZoom(e.target.value)
          }}
        />

        <p>ROTATION</p>
        <Slider
          type="range"
          value={rotate} min={0} max={360} step={1}
          aria-labelledby="Rotate"
          onChange={(e:any) => {
            setRotate(e.target.value)
          }}
        />
      </Controls>
        {
          (SITE_LANG === 'kr')?
          <Guide><h4>정사각형 영역이 <GreenText>크리스마</GreenText><RedText>쮸</RedText>로 만들어져요</h4>
            <p>이미지를 이동하거나 편집해서 정사각형 안의 영역을 조절할 수 있어요</p>
          </Guide>
          :<Guide>
            <h4>The area highlighted by the square<br/>will have the <GreenText>Kiss</GreenText><RedText>mas</RedText> filter applied.</h4>
          </Guide>
        }
        

      <FooterBtnRelativeWrapper>
      
        <EnGreenLineButton $isEn={SITE_LANG !== 'kr'} whileTap={{ scale: 0.9 }} onClick={onChangeImage}>
          {(SITE_LANG === 'kr')?'다른 사진 찾아보기':'Browse other photos'}
        </EnGreenLineButton>
        <EnGreenButton $isEn={SITE_LANG !== 'kr'} whileTap={{ scale: 0.9 }} onClick={onCompEdit}>
          {(SITE_LANG === 'kr')?'편집 완료하기':'Select Editing'}
        </EnGreenButton>
      </FooterBtnRelativeWrapper>
    </ModalContents>
  );
};

export default CropModal;
