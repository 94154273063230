
import NavLinkItem from '@components/NavLinkItem';
import { BodyEn, ExampleBox, ExampleBoxTwoContainer, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';
import { BoxVars, CircleVars } from '@/styles/animAssets';
import { styled } from 'styled-components';

import guide1 from '@assets/sample_guide_before.png';
import guide2 from '@assets/sample_guide_after.png';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { getNickName, getTicketCnt } from '@/apis/users';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { alertModal, userData } from '@/atoms';
import { IS_LOCAL_TEST, MAIN_ROUTE } from '@/Data';
import { useState } from 'react';

// ================================================================================
// styled

// ================================================================================
const ExampleItem = styled.div<{$isBefore:Boolean}>`
  & > p { 
    width: 100%; text-align: center; margin: 5px 0; font-size:26px; font-weight: bold; 
    color:${({$isBefore})=> $isBefore? `#23856D`:`#006F57`}; 
  }
`;

const BottomSummery = styled.p`
font-weight: bold !important; margin-top: 150px;
  a { font-weight:400; font-size:23px;}
`
// ================================================================================

function GuidePage() {
  const navigate = useNavigate();
  
  const {run} = useAsyncCallback(getTicketCnt);
  const [user, setUser] = useRecoilState(userData);
  const {run:getNickNameRun} = useAsyncCallback(getNickName);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const [isProcess, setProcess] = useState<boolean>(false);

  const nickNameCheck = () => {
    if(IS_LOCAL_TEST){
      navigate(MAIN_ROUTE+'making');
      return;
    }
    if(isProcess) return;

    setProcess(true);
    //nickName이 없으면 닉네임 입력 페이지로 이동
    if(user.nickname === '' || user.role === ''){
      getNickNameRun().then((res:any) => {
        if(res && res.status === 200){
          setUser({...user, nickname:res.data.result.nickname, role:res.data.result.role});

          nextStepTicketCheck();
        }else{
          setProcess(false);
        }
      });
    }else{
      setProcess(false);
      nextStepTicketCheck();
    }

  }

  const nextStepTicketCheck = () => {
    run().then((res:any) => {
      console.log(res);
      if(res && res.status === 200){
        const ticketCnt:number = res.data.result.ticket_cnt;
        const isShareEvent:number = res.data.result.is_shared;
        let isJoinEventCheck:boolean = (isShareEvent? ticketCnt<6 : ticketCnt<5);
        setProcess(false);

        if(isJoinEventCheck || user.role === 'admin'){
          if(user.role === 'admin') alert("admin"); 
          navigate(MAIN_ROUTE+'making');
        }else{
          setAlert({isShow:true, text:<>The time for spreading<br/>Kissmas cheer has run out</>});
        }
      } else {
        setProcess(false);
      }
    });
  }

  return (
        <BodyEn>
          <h2>Any photo is fine</h2>
          <p>
          Choose a photo taken on a special day<br/>or in a memorable location<br/>
          Our <GreenText style={{fontWeight:'bold'}}>Kiss</GreenText><RedText style={{fontWeight:'bold'}}>mas</RedText> AI will take care of the cute kisses!
          </p>
          <ExampleBoxTwoContainer variants={BoxVars} initial="start" animate="end">
            <ExampleItem $isBefore>
              <ExampleBox variants={CircleVars} $imgUrl={guide1}/>
              <p>Before</p>
            </ExampleItem>
            <ExampleItem $isBefore={false}>
              <ExampleBox variants={CircleVars} $imgUrl={guide2}/>
              <p>After</p>
            </ExampleItem>
          </ExampleBoxTwoContainer>
          <BottomSummery>
            A <GreenText style={{fontWeight:'bold'}}>Kiss</GreenText><RedText style={{fontWeight:'bold'}}>mas</RedText> kiss only takes the blink of an eye<br/>
            <a>Show your feelings to everyone who is important to you - family, <br/>friends, significant others!</a>
          </BottomSummery>
          <FooterBtnWrapper>
            <NavLinkItem onClick={nickNameCheck} child={'Start creating Kissmas cheer'} isButton></NavLinkItem>
          </FooterBtnWrapper>
        </BodyEn>
  );
}
export default GuidePage;
