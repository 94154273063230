
import styled from "styled-components";

import { ISnsTagetType, handleCopyClipBoard, snsShareHandler } from "@/hooks/snsFunc";
import { GreenText, RedText } from "@/styles/main";
import { motion } from "framer-motion";

import share_icon_fb from '../assets/share_icon_fb.png';
import share_icon_kakao from '../assets/share_icon_kk.png';
import share_icon_line from '../assets/share_icon_na.png';
import share_icon_x from '../assets/share_icon_x.png';
import { alertModal, makeProjectData, userData } from "@/atoms";
import { useRecoilState } from "recoil";
import { APP_DATA, SERVICE_DATA, SITE_LANG } from "@/Data";

// ================================================================================
// styled
const ShareModalContainer = styled.div`
  display: flex; flex-direction: column; justify-content: center; align-items: center;
  & > h2 { font-weight:700; font-size:40px; margin:0; margin-top:40px;}
  & > h2 > span { font-size:40px !important;}
  & > p { text-align: center; margin:0; margin-bottom:60px;}
  & > button { width: 300px; }
  & a { color: #888;  font-size:25px; }
  & > button { border-radius:25px; width:200px; height:64px; color:#515151; font-size:25px; border:1px solid #e8e8e8; background-color:#FAF8F8; margin-bottom:50px; box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.15);}
  & > span { color:#6F6F6F; font-size:20px; margin-bottom:10px;}
  & span {  display: inline-block !important; }
`;

const FrameListContainer = styled.div`
  width: 100%; height: auto;
  margin-bottom: 45px; display:flex; justify-content:center; align-items:center;

  & > div { width:109px; height:109px; margin:0 13px;  border-radius: 100px; background-size:100%;}
`;

const SnsButton = styled(motion.div)<{$color?:string, $image:any}>`
  background-color: ${props => props.$color? props.$color : 'red'};
  background-image: url(${props => props.$image});
  box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.1);
`

// ================================================================================
//

type ShareModalProps = {
  onComped: () => void;
}

// ================================================================================
//
// ===============================================================================
function ShareModal(props:ShareModalProps) {
  const SelectOpations:ISnsTagetType[] = ['kakao', 'line', 'facebook', 'x'];
  const SnsListColors:string[] = ['#FDDC3F', '#03C75A', '#4267B2', '#FFFFFF'];
  const SnsImage:any[] = [share_icon_kakao, share_icon_line, share_icon_fb, share_icon_x];

  const [user] = useRecoilState(userData);
  const [projectData] = useRecoilState(makeProjectData);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const copyUrl = ()=> {
    const _text = (SITE_LANG === 'en')? 'Copied to clipboard!' : '클립보드에 복사 되었습니다!';
    handleCopyClipBoard(SERVICE_DATA.SHARE_ROOT+projectData.id);
    setAlert({isShow:true, icon:'success', text:<>{_text}</>}) 
  }
  return (
    <ShareModalContainer>
      {(SITE_LANG === 'en')? 
        <h2 style={{textAlign:'center', marginBottom:23}}>Send <GreenText>Kiss</GreenText><RedText>mas</RedText> cheer<br/>to your loved ones!</h2>:
        <h2>소중한 사람에게 <GreenText>크리스마</GreenText><RedText>쮸~</RedText></h2>
      }
      {(SITE_LANG === 'en')? 
        <p style={{width:'100%'}}>For those who shared,<br/>we will give you one more chance at Kissmas<br/><a style={{ margin: '15px 0 0', display:"block"}}>*Available up to once per account</a></p>:
        <p>공유하면 한 번 더 크리스마쮸를 만들 수 있어요<br/><a>*계정 당 최대 1회 제공</a></p>
      }
      <FrameListContainer>
        {SelectOpations.map((item,idx) => (
          <SnsButton key={idx} onClick={()=>snsShareHandler(item, user?.nickname, projectData.id??'')} whileTap={{ scale: 0.9 }} $color={SnsListColors[idx]} $image={SnsImage[idx]}></SnsButton>
        ))}
      </FrameListContainer>
      <motion.button whileTap={{ scale: 0.9 }} onClick={copyUrl}>{(SITE_LANG === 'en')? 'Copy URL':'URL 복사'}</motion.button>
      {(SITE_LANG === 'en')? 
        <span style={{fontSize:18}}>*Sharing links can only be created immediately after making a Kissmas picture</span>:
        <span>*공유 링크는 크리스마쮸를 만든 직후에만 생성할 수 있어요</span>
      }
    </ShareModalContainer>
  );
};

export default ShareModal;
