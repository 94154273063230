import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { styled } from 'styled-components';

import { myInfo } from '@/apis/users';
import { alertModal, isGlobalLoading, userData } from '@/atoms';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { Body, FooterBtnWrapper, GreenButton, GreenText, RedText, Spinners } from '@/styles/main';
import { IUserData } from '@/types/Types';
import saveAs from 'file-saver';
import { dataURLtoFile } from '@/libs/Utils';
import { motion } from 'framer-motion';
import { IS_DEVICE } from '@/libs/Utils';
import { MAIN_ROUTE } from '@/Data';

// ================================================================================
// styled
// ================================================================================
const Info = styled.p`
  margin-bottom: 90px;
`;

const Hint = styled.a`
  font-size: 20px; color: #6F6F6F; line-height: 34px;
`;

const MyMakingList = styled.div`
  border-radius: 20px; border:3px solid #000; background-color: #f8f8f8;
  min-height: 100px; margin: 20px 0; margin-bottom: 100px;
`

const MakingEmptyBox = styled.div`
  display: flex; justify-content: center; align-items: center;
  height: 600px;
  font-size: 30px; font-weight: bold;;
`

const ImageList = styled.div`
  display: flex; flex-wrap: wrap; justify-content: space-between;
  padding: 5px 22px;
  & > a { width:292px; height: 292px; display: inline-block; margin:15px 0;}
  & > a img { width:100%; height:100%; border-radius: 10px;  overflow: hidden;  }
`;

const Loading = styled.div`
  width: 100%; height: 700px; display: flex; justify-content: center; align-items: center;
`

// ================================================================================

function MyPage() {
  const navigate = useNavigate();

  const [isLoadings, setLoading] = useRecoilState(isGlobalLoading);
  const [user, setUser] = useRecoilState(userData);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const {run} = useAsyncCallback(myInfo);
  const ticket_length = 5;

  useEffect(() => {
    if(!user.isDetailInit){
      run().then((res:any) => {
        if(res && res.data){
          setUser({...res.data.result as IUserData, is_login:true, isDetailInit:true});
        }else{
          // window.location.replace('/');
        }
        console.log(res);
      });
    }
  }, []);

  const changeBase64ToImage = (baseStr:string) => ('data:image/png;base64,'+baseStr);
  const changeBase64ToShareAction = async (baseStr:string) => {
    const _fileName = 'kissmas_'+(new Date().getTime())+'.png';
    const _fileData = dataURLtoFile('data:image/png;base64,'+baseStr, _fileName);

    if (navigator.share && _fileData && !IS_DEVICE.ANDROID && !IS_DEVICE.DESKTOP) {
      try {
          // Call the share() method to open the native share dialog
          await navigator.share({ files: [_fileData] });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing content:', error);
        }
    }else{
      saveAs('data:image/png;base64,'+baseStr, _fileName);
    }
  }

  const loginDataInit = () => {
    setUser({is_login:false, nickname:'', ticket_cnt:0, files:[], isDetailInit:false, role:''});
    localStorage.removeItem("token");
  }

  const logoutHandler = () => {
    loginDataInit();
    
    setAlert({isShow:true, text:<>You are logged out.</>, compFn:()=>{
      navigate(MAIN_ROUTE);
    }});
  };

  const resignClickHandler = () => {
    setAlert({isShow:true, text:<>If you delete your account,<br/>all Kissmas photos will be deleted<br/>and cannot be recovered.</>, 
    compFn:()=>{
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(MAIN_ROUTE+'seeyou');
      }, 1000);
    }, cancleFn:()=>{},
    compText:'Delete account'});
  }

  return (
    
        <Body>
          {user.isDetailInit?
            <>
              <h2>{user.nickname}</h2>
              <Info>
                So far, I have made a total of {user.files.length} <RedText>Kiss</RedText><GreenText>mases</GreenText><br/>
                You can make {(ticket_length - user.ticket_cnt >= 0? ticket_length - user.ticket_cnt : 0)} more <RedText>Kiss</RedText><GreenText>mases</GreenText> in the future
              </Info>

              <h3><RedText>Kiss</RedText><GreenText>mas</GreenText> made by {user.nickname}</h3>
              <Hint>*You can download the photo by clicking on the photo<br/>Kissmases will be deleted 30 days from the date of creation</Hint>
            </>:
            <Loading><Spinners/></Loading>
          }
          {user.isDetailInit?
            <MyMakingList>
              {
                user?.files.length === 0 && <MakingEmptyBox>I haven’t spread <RedText style={{marginLeft:4}}>Kiss</RedText><GreenText style={{marginRight:4}}>mas</GreenText> cheer yet!</MakingEmptyBox>
              }
              <ImageList>
                { user?.files.length !== 0 && user?.files.map((file, idx) => {
                  return (<motion.a whileTap={{ scale: 0.95 }} onClick={()=>changeBase64ToShareAction(file.image)} key={idx}><img src={changeBase64ToImage(file.image)} alt={file.id}/></motion.a>)
                })}
              </ImageList>
            </MyMakingList>
          :null}
          {user.isDetailInit?
          <FooterBtnWrapper>
            <GreenButton whileTap={{ scale: 0.9 }} onClick={logoutHandler}>Logout</GreenButton>
            <a onClick={resignClickHandler} style={{textDecoration:'underline'}}>Delete my account</a>
          </FooterBtnWrapper>
          :null}
        </Body>
  );
}

export default MyPage;
