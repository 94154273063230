export const SetCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

export const GetCookie = (name: string) => {
    const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return value ? value[2] : null;
}
export const dataURLtoFile = (dataurl:string, fileName:string) => {
    var arr:string[] = dataurl.split(',');
    var match = arr[0]?.match(/:(.*?);/);

    if(match && match.length > 0){
        var mime = match[1]??'',
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});
    }
    return null;   
}

export const IS_DEVICE = {
    ANDROID : navigator.userAgent.match(/Android/i),
    IOS : navigator.userAgent.match(/ios/i),
    DESKTOP : !navigator.userAgent.match(/(Android|iPhone|iPod)/i),
}
