import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { styled } from 'styled-components';

import { Body, GreenText, RedText } from '@/styles/main';
import { API_SERVER_URL, SITE_LANG } from '@/Data';

import fbLoginBtn from '@assets/login_btn_fb_en.png';
import kkLoginBtn from '@assets/login_btn_kakao_en.png';
import nvLoginBtn from '@assets/login_btn_naver_en.png';
import ggLoginBtn from '@assets/login_btn_google_en.png';
import { userData } from '@/atoms';


// ================================================================================
// styled
const BodyWrapper = styled(Body)`
  & > p { margin-bottom: 140px;}
`;

const LoginForms = styled.div`
  width: 100%; text-align: center;
  /* & > button { width:95%; padding: 20px; border-radius:20px; background-color:black; color:white; font-size: 30px;} */
  & img { width:390px; margin-bottom:30px; cursor: pointer;}
`;

// ================================================================================

// ================================================================================

function LoginPage() {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userData);
  
  const loginBtnSocial = (social:string) => {
    localStorage.setItem('token_provider', social);
    localStorage.setItem('language', SITE_LANG);
    window.location.href = `${API_SERVER_URL}en/api/auth/login/${social}`
  };
  
  useEffect(()=>{
    // if(user.is_login) navigate('/', { replace: true });
  },[]);

  return (
    <BodyWrapper>
      <h2>LOGIN</h2>
      <p>When you create an account, <br/>
        you can send five <GreenText>Christmas</GreenText> <RedText>kisses</RedText> for free!
      </p>
      
      <LoginForms>
        <a onClick={()=>loginBtnSocial('google')}><img src={ggLoginBtn}/></a>
        <img onClick={()=>loginBtnSocial('naver')} src={nvLoginBtn}/>
        {/* <img src={fbLoginBtn}/> */}
        <img onClick={()=>loginBtnSocial('kakao')} src={kkLoginBtn}/>
      </LoginForms>
    </BodyWrapper>
  );
}

export default LoginPage;
