
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { styled } from 'styled-components';

import { alertModal, pageAnim, userData } from '@/atoms';

import homeIcon from '@assets/icon-home.png';
import backIcon from '@assets/icon-back.png';
import myIcon from '@assets/icon-my.png';
import langIcon from '@assets/icon-lang.png';
import { MAIN_ROUTE, SITE_LANG } from '@/Data';

// ================================================================================
// styled

// ================================================================================
const HeaderWrapper = styled.header`
  width: 100%; height: 88px; display: flex; align-items: center; justify-content: space-between;
  position: absolute; z-index: 99; max-width: 750px; left: 50%; transform: translateX(-50%); top:0;

  & > div { width:88px; height:88px; margin:0 10px; display:flex; justify-content:center; align-items:center; }
  & div.my img { width:52px; }
  & div.lang img { width:45px; position:relative; top:2px; }
  & div.back img { width:50px; }
  & div.back img.back { width:40px; }

  & span { display:flex; width:176px; flex-direction:row-reverse; margin-right:20px;}
  & span > div { width:55px; height:88px; margin:0 12px; display:flex; justify-content:center; align-items:center; }
`;

const TopIconBtn = styled.div<{$isShow:Boolean, $isDisable:Boolean}>`
  & a > img { width:70px; }
  ${({$isShow}) => $isShow? ``:`opacity:0; pointer-events: none;`}
  /* ${({$isDisable}) => $isDisable? `pointer-events: none;` : ''} */
`;

// ================================================================================

// function NavLinkItem(props:NavItemProps) {
function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  // const [currentStep] = useRecoilState(makeSteps);
  const [user] = useRecoilState(userData);
  const [isMaking, setIsMaking] = useState<Boolean>(false);
  const [isBackBtn, setIsBackBtn] = useState<Boolean>(false);
  const [isMainPage, setMainPage] = useState<Boolean>(false);
  const [isHomeBtn, setIsHomeBtn] = useState<Boolean>(false);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const [anim,setAnim] = useRecoilState(pageAnim);

  useEffect(() => {
    (location.pathname === MAIN_ROUTE || location.pathname === "" || location.pathname === "/en")? setMainPage(true):setMainPage(false);
    (location.pathname === MAIN_ROUTE+'making' || location.pathname === MAIN_ROUTE+'result')? setIsMaking(true):setIsMaking(false);
    (location.pathname === MAIN_ROUTE+'result' || location.pathname === MAIN_ROUTE+'seeyou')? setIsHomeBtn(true):setIsHomeBtn(false);
    (location.pathname === MAIN_ROUTE || location.pathname === "" || location.pathname === "/en")? setIsBackBtn(false) : setIsBackBtn(true);
    console.log(location.pathname,MAIN_ROUTE);
  }, [location]);

  const myPageClickHandler = () => {
    setAnim('push');
    if(location.pathname !== MAIN_ROUTE+'login'){
      user.is_login? navigate(MAIN_ROUTE+'my') : navigate(MAIN_ROUTE+'login');
    }
  };

  const changeLang = ()=>{
    setAnim('push');
    window.location.replace((location.pathname === '/')? '/en/' : '/');
  }

  const gobackClickHandler = () => {
    const _textDom:JSX.Element = 
      (SITE_LANG === 'kr')?
        <>프레임 설정과 공유 링크 생성은<br/>현재 페이지에서만 진행 할 수 있습니다.<br/>정말 나가시겠습니까?</>:
        <>The picture can only be shared and<br/>have frames applied on this page.<br/>Are you sure you want to leave?</>;

    const _btnText = (SITE_LANG === 'kr')? '홈으로':'Go home';
    setAnim('back');

    if( location.pathname === MAIN_ROUTE+'result' ){
      setAlert({isShow:true, text:_textDom, compFn:()=>{navigate(MAIN_ROUTE);},cancleFn:()=>{}, compText:_btnText});
    }else if(location.pathname === MAIN_ROUTE+'seeyou' || location.pathname === MAIN_ROUTE+'login'){
      navigate(MAIN_ROUTE);
    }else{
      navigate(-1);
    }
  };
  
  return (
    <HeaderWrapper>
      
      <TopIconBtn className='back' $isShow={isBackBtn} $isDisable={false} onClick={gobackClickHandler}>
        {isHomeBtn?
            <img src={homeIcon} alt='홈으로 버튼'/>:
            <img src={backIcon} className='back' alt='뒤로가기 버튼'/>
        }
      </TopIconBtn>

      <span>
      {(isMainPage && 
        <TopIconBtn className='lang' $isShow={isMainPage} onClick={changeLang} $isDisable={location.pathname === '/' || location.pathname === '/en/'} >
          <img src={langIcon} alt='영문변환'/>
        </TopIconBtn>
      )}
        {(user.is_login && 
          <TopIconBtn $isShow={user.is_login} className='my' onClick={myPageClickHandler} $isDisable={location.pathname === '/my' || location.pathname === '/en/my'} >
            <img src={myIcon} alt='내정보가기 버튼'/>
          </TopIconBtn>
        )}
        
        
      </span>
    </HeaderWrapper>
  );
}

export default Header;