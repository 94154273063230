import { Body, GreenText, RedText } from '@/styles/main';
import { styled } from 'styled-components';
import seeyouTree from '../assets/seeyou_tree.png';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userData } from '@/atoms';
// ================================================================================
// styled
// ================================================================================
const TreeImg = styled.img`
  width: 90%; left:5%; position:relative;`
// ================================================================================

function SeeYouPage() {
  const [user, setUser] = useRecoilState(userData);
  useEffect(()=>{
    setUser({is_login:false, nickname:'', ticket_cnt:0, files:[], isDetailInit:false, role:''});
    localStorage.removeItem("token");
    localStorage.removeItem('token_provider');
  },[]);

  return (
        <Body>
          <h2>
            <GreenText>H</GreenText>
            <RedText>a</RedText>
            <GreenText>p</GreenText>
            <RedText>p</RedText>
            <GreenText>y</GreenText> <GreenText>H</GreenText>
            <RedText>o</RedText>
            <GreenText>l</GreenText>
            <RedText>i</RedText>
            <GreenText>d</GreenText>
            <RedText>a</RedText>
            <GreenText>y</GreenText>
            <RedText>!</RedText>
          </h2>
          <p>디오비스튜디오는 추억이 담긴 사진을<br/>
          AI 기술을 활용하여 쉽고 빠르게 보정할 수 있는<br/>
          서비스를 준비하고 있어요</p>
          <TreeImg src={seeyouTree}/>
          <h3>많이 기대해 주세요!</h3>
        </Body>
  );
}

export default SeeYouPage;
