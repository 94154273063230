
import { styled } from 'styled-components';

import { useRecoilState } from 'recoil';
import { modal, isGlobalLoading, alertModal, makeProjectData, userData } from '../atoms';
import { useEffect, useState } from 'react';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import frame1 from '../assets/frame1.png';
import frame2 from '../assets/frame2.png';
import frame3 from '../assets/frame3.png';
import frame4 from '../assets/frame4.png';
import frame5 from '../assets/frame5.png';
import frame6 from '../assets/frame6.png';

import frameThumbsNone from '../assets/frame_thumb0.png';
import frameThumbs1 from '../assets/frame_thumb1.png';
import frameThumbs2 from '../assets/frame_thumb2.png';
import frameThumbs3 from '../assets/frame_thumb3.png';
import frameThumbs4 from '../assets/frame_thumb4.png';
import frameThumbs5 from '../assets/frame_thumb5.png';
import frameThumbs6 from '../assets/frame_thumb6.png';

import oneMorePlz from '../assets/one-more-plz.png';

import { Body, GreenButton, GreenLineButton, GreenText, RedText } from '@/styles/main';
import { motion } from 'framer-motion';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { setShareIdx } from '@/apis/process';
import { IS_DEVICE } from '@/libs/Utils';
import { useLocation, useNavigate } from 'react-router-dom';

// ================================================================================
// styled 

const FrameThumbsContainer = styled.div`
  height: 130px; position: relative; left: -42px; background-color: #FAF8F8; white-space:nowrap; width: 750px;
  margin-bottom: 30px; justify-content:center; align-items:center; white-space:nowrap; overflow-x: auto; text-align:center; overflow-y: hidden;

  .frameIdx4 { display: none; }
  
  & > div:first-child { margin-left:40px;}
  & > div:last-child { margin-right:40px;}
`;

const FrameThumbs = styled.div<{$bgImg?:string, $isSelected:Boolean}>`
  width:100px; height:100px; background-color:#D9D9D9; margin:10px 10px;  border-radius: 24px; background-size:100%; display:inline-block; 
  ${({$bgImg}) => ($bgImg)? `background-image: url(${$bgImg});` : '' }
  ${({$isSelected}) => ($isSelected)?'border: 4px solid red;':'border: 4px solid #FAF8F8;'}
`;

const CardFrameWrapper = styled.div`
  border: 3px solid black; width: 660px; padding:20px 0 0; margin-bottom:50px; display: flex; justify-content: center; border-radius: 20px;
  flex-direction: column; align-items: center; position: relative; left: 50%; margin-left: -330px;

  & button { padding:10px 30px; margin:0 10px; }
`;

const BtnWrapper = styled.div`
  display: flex; justify-content: center; align-items: center; position: relative;
  
  & > button { min-width: 250px !important; padding:20px 25px !important; }
`;

const ResultBox = styled.div<{$isResutImg:String, $isSmall:Boolean, $isDownMode:Boolean}>`
  width: 606px; height: 606px; overflow: hidden; border-radius: 0;
  position: relative; background-repeat: no-repeat;
  
  ${({$isDownMode})=>$isDownMode? '':'box-shadow: 0px 6px 14px 5px rgba(0,0,0,0.15);'}
  
  background-image: url(${({$isResutImg}) =>`${$isResutImg}`});
  background-size: ${({$isSmall})=> $isSmall?`440px 440px` : '100% 100%'};
  background-position: ${({$isSmall})=> $isSmall?`center 110px` : 'center'};
  
  & > img { width:100%; height:100%; margin:0; }
`;

const FooterBtnContainer = styled.div`
  width: 100%; display: flex; justify-content: center; margin-top: 29px;
`;

const TestBox = styled.div`
  width: 100px; height: 100px; position: absolute; top: 0; left:50%; margin-left: -50px; z-index: 999;
`

// ================================================================================
//

function ResultPage() {
  const navigate = useNavigate();
  const SelectOpations = ['', frame1, frame2, frame3, frame4, frame5, frame6 ];
  const SelectThumbs = [frameThumbsNone, frameThumbs1, frameThumbs2, frameThumbs3 , frameThumbs4, frameThumbs5 , frameThumbs6];
  const [selectedFrame, setSelectedFrame] = useState<number>(0);
  const [isSmallPic, setSmallPic] = useState<boolean>(false);
  const [downloadImageMode, setDownloadImageMode] = useState<boolean>(false);
  // direct image test state
  const [testDown, setTestDown] = useState<number>(7);

  const [loading, setLoading] = useRecoilState(isGlobalLoading);
  const [modals, setModal] = useRecoilState(modal);
  const [user, setUser] = useRecoilState(userData);
  const [projectData] = useRecoilState(makeProjectData);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const {run:setFrameSaveRun} = useAsyncCallback(setShareIdx);

  useEffect(() => {
    if(!projectData.resultImageURL && projectData.resultImageURL === ''){
      setAlert({isShow:true, text:<>잘못된 접근입니다</>, compFn:()=>{
        navigate('/login', { replace: true });
      }});
    }
    //개인정보를 리셋시키기 위해 init를 false
    setUser({...user, isDetailInit:false});
  },[]);

  const frameSelectHandler = (idx:number) => {
    (idx>2)? setSmallPic(true) : setSmallPic(false);
    setSelectedFrame(idx);
  }

  const shareModalOpen = () => {
    setLoading(true);
    setFrameSaveRun(projectData.id, String(selectedFrame)).then((res:any)=>{
      console.log(res);
      setLoading(false);
      setModal({isShow:true, target:'share'});
    })
  }

  const testDownload = () => {
    if(testDown !== 0) {
      setTestDown(testDown-1);
      return;
    }
    alert("test");
    alinkDonwloadFrame();
  }

  const alinkDonwloadFrame = () => {
    // andorid, desktop 다운로드
    const resultFrame = document.querySelector('.resultFrame');

    if(resultFrame){
      setDownloadImageMode(true);
      setLoading(true);
      const _fileName = 'kissmas_'+(new Date().getTime())+'.png';
      setDownloadImageMode(true);
      domtoimage.toPng(resultFrame).then(pngUrl => {
        // 안드로이드 처리용
        const link = document.createElement('a');
        link.href = pngUrl;
        link.setAttribute(
          'download',
          _fileName,
        );
        setDownloadImageMode(false);

        document.body.appendChild(link);
          setTimeout(() => {
            link.click();
            link?.parentNode?.removeChild(link);
            
            setDownloadImageMode(false);
            setLoading(false);
          },1000)
          console.log(pngUrl)
      });
      
    }

  }

  const downloadFrameHandler = () => {
    if(IS_DEVICE.ANDROID || IS_DEVICE.DESKTOP){
      alinkDonwloadFrame();
      return;
    }
    const resultFrame = document.querySelector('.resultFrame');

    if(resultFrame){
      setDownloadImageMode(true);
      setLoading(true);
      domtoimage.toBlob(resultFrame).then(blobs => {
        // 한번에 다운로드 되지않는걸 방지하기 위해 중복 호출
        domtoimage.toBlob(resultFrame).then(blob => {
          if(blob && blob.size > 10000){
            shareActionDownload(blob);
          }else{
            setAlert({isShow:true, text:<>이미지 저장에 실패했습니다. 다시 한번 다운로드 버튼을 눌러주세요!</>});
          }
          setDownloadImageMode(false);
          setLoading(false);
        });
      });
      
    }
  }

  const shareActionDownload = async (blob:Blob) => {
    const _fileName = 'kissmas_'+(new Date().getTime())+'.png';
    if (navigator.share) {
      try {
          // Call the share() method to open the native share dialog
          await navigator.share({
            files: [new File([blob], _fileName, {type: "image/png"})],
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing content:', error);
        }
    }else{
      saveAs(blob, _fileName);
    }
  }

  return (
    <Body>
      <h2><GreenText>크리스마</GreenText><RedText>쮸</RedText> 완성!</h2>
      <FrameThumbsContainer>
        {SelectThumbs.map((item,idx) => (
          <FrameThumbs key={idx} className={'frameIdx'+idx} onClick={()=>frameSelectHandler(idx)} $isSelected={selectedFrame===idx} $bgImg={item} />
        ))}
      </FrameThumbsContainer>
      <CardFrameWrapper>
        {projectData.resultImageURL? <ResultBox $isResutImg={projectData.resultImageURL} className="resultFrame" $isSmall={isSmallPic} $isDownMode={downloadImageMode}>
          {(SelectOpations[selectedFrame] !== '') && <img src={SelectOpations[selectedFrame]} alt='사진의 크리스마스 프레임' />}
        </ResultBox>:<div>Error</div>}
        <br/>
      </CardFrameWrapper>
      <BtnWrapper>
        <GreenLineButton onClick={downloadFrameHandler}>저장하기</GreenLineButton>
        <GreenButton whileTap={{ scale: 0.9 }} onClick={shareModalOpen}>공유하기</GreenButton>
        <motion.img 
          initial={{ opacity: 0, top: 30 }} 
          animate={{ opacity: 1, top: 85 }} 
          transition={{ duration: 0.7, delay:2, ease: "backInOut" }} 
          src={oneMorePlz} 
          alt='한번더 하고 티켓받기' 
          style={{position:'absolute', left:340, width:253}}/>
      </BtnWrapper>
      <TestBox onClick={testDownload}></TestBox>
    </Body>
  );
}

export default ResultPage;
