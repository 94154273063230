import React from 'react';

import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { createBrowserHistory } from 'history';
import ReactGA from "react-ga4";

import { 
  modal, 
  pageAnim, 
  isGlobalLoading,
  makeProjectData, 
  alertModal, 
  userData} from '@/atoms';
  import { IS_LOCAL_TEST, MAIN_ROUTE, SITE_LANG } from './Data';

import MainPageEn from '@routes/en/MainPage';
import GuidePageEn from '@routes/en/GuidePage';
import LoginPageEn from '@routes/en/LoginPage';
import ErrorPageEn from '@routes/en/ErrorPage';
import LoginLandingPageEn from '@routes/en/LoginLandingPage';
import PhotoEditorPageEn from '@routes/en/PhotoEditorPage';
import ResultPageEn from '@routes/en/ResultPage';
import MyPageEn from '@routes/en/MyPage';
import SeeYouPageEn from '@routes/en/SeeYouPage';

import MainPage from '@routes/MainPage';
import GuidePage from '@routes/GuidePage';
import LoginPage from '@routes/LoginPage';
import ErrorPage from '@routes/ErrorPage';
import LoginLandingPage from '@routes/LoginLandingPage';
import PhotoEditorPage from '@routes/PhotoEditorPage';
import ResultPage from '@routes/ResultPage';
import MyPage from '@routes/MyPage';
import SeeYouPage from '@routes/SeeYouPage';

import { useAsyncCallback } from '@hooks/extended-axios/hooks';
import { verifyToken } from '@apis/users';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Modals from '@components/Modals';
import CropModal from '@modals/CropModal';
import ShareModal from '@modals/ShareModal';
import AlertModal from './components/AlertModal';
import SpinerLoading from './components/SpinerLoading';


// ================================================================================
// styled
declare global {
  interface Window {
    Kakao: any;
  }
}

type ITokenData = {
  token: string;
}


// ==================================================================================

function App() {
  // const [isNavOpen, setNavOpen] = useState(false);
  const { Kakao } = window;
  const navigate = useNavigate();
  const location = useLocation();
  const history = createBrowserHistory();

  const [modals, setModal] = useRecoilState(modal);
  const [user, setUser] = useRecoilState(userData);
  const [isLoading] = useRecoilState(isGlobalLoading);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const [pageAnims, setPageAnim] = useRecoilState(pageAnim);
  const [projectData, setProjectData] = useRecoilState(makeProjectData);
  
// const [currentStep, setCurentStep] = useRecoilState(makeSteps);
  const [targetModalTitle,setTargetModalTitle] = useState("");
  const [targetModal,setTargetModal] = useState<JSX.Element>(<></>);
  
  const {run} = useAsyncCallback(verifyToken);
  const initAppData = () => {
    setProjectData({url:'', data:[], id:'', resultImageURL:''});
    
    if(IS_LOCAL_TEST) setUser({...user, is_login:true, nickname:'test', ticket_cnt:0, files:[], isDetailInit:false});
  }
  const modalCloseHandler = () => setModal({isShow:false, target:'crop'});
  

  // ================================================================================
  // hooks

  useEffect(() => {
    // init hooks.
    Kakao.cleanup();
    Kakao.init(process.env.KAKAO_API_KEY);
    ReactGA.initialize(process.env.GA_ID??'');
    loginCheck();
    // inAppCheck();
  
    (SITE_LANG === 'en')? document.body.classList.add('en') : document.body.classList.add('kr');

    const listenBackEvent = () => {
      setModal({isShow:false, target:'crop'});
      setPageAnim('back');
    };

    const unlistenHistoryEvent = history.listen((e) => {
      console.log(e);
      if (e.action === "POP") {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, []);

  useEffect(() => {
    // page change AppData init hooks.
    if(location.pathname === MAIN_ROUTE || location.pathname.indexOf(MAIN_ROUTE+'guide')>-1){
      initAppData();
    }
  }, [location]);

  useEffect(() => {
    // modals control hooks.
    let targetModalItem:JSX.Element = <></>;
    switch(modals.target){
      case 'crop' : 
        setTargetModalTitle('');
        targetModalItem = <CropModal onComped={modalCloseHandler}/>; 
      break;
      case 'share' : 
        setTargetModalTitle('_');
        targetModalItem = <ShareModal onComped={modalCloseHandler}/>; 
      break;
    }
    setTargetModal(targetModalItem);
  }, [modals.isShow]);

  // ================================================================================
  // funcs
  const loginCheck = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params:ITokenData = Object.fromEntries(searchParams.entries()) as ITokenData;
  
    if(params.token){
      // URL params에 token이 있을경우 유효토큰 검증
      const replaceRoute = localStorage.getItem('language') === 'en'? '/en/':'/';
      localStorage.setItem('token', `Bearer ${params.token}`);
      run().then((res:any)=>{
        if(!res){
          setAlert({isShow:true, text:<>토큰 정보가 유효하지 않습니다.</>});
          localStorage.setItem('token', '');
          localStorage.setItem('token_provider', '');
        }
        window.location.replace(replaceRoute);
      });
    }
  }

  const inAppCheck = () => {
    // if (navigator.userAgent.indexOf('KAKAO') >= 0) {
    //   setAlert({isShow:true, text:<>카카오톡 브라우저에서<br/>다운로드 기능이 지원되지 않습니다.<br/>다른 인터넷 브라우저를 이용하세요</>});
    //   setInApp(true);
    // }
    // if (navigator.userAgent.indexOf('[FB') >= 0) setAlert({isShow:true, text:<>페이스북 브라우저에서<br/>일부 동작이 원할하지 않을 수 있습니다.<br/>다른 인터넷 브라우저를 이용하세요</>});
    // if (navigator.userAgent.indexOf('Instagram') >= 0) setAlert({isShow:true, text:<>인스타그램 브라우저에서<br/>일부 동작이 원할하지 않을 수 있습니다.<br/>다른 인터넷 브라우저를 이용하세요</>});
    // if (navigator.userAgent.indexOf('trill') >= 0) setAlert({isShow:true, text:<>틱톡 브라우저에서<br/>일부 동작이 원할하지 않을 수 있습니다.<br/>다른 인터넷 브라우저를 이용하세요</>});
  };
  
  return (
        <div className="App">
          
          {/* route pages+ page change Anim libs */}
          <TransitionGroup 
            className={'transition-wrapper'} 
            childFactory={(child) => {
              return React.cloneElement(child, {
                classNames:pageAnims,
              });
            }}>
            <Header />
            <CSSTransition timeout={300} classNames={pageAnims} key={location.pathname}>
              <Routes location={location}>

                {/* KOR page */}
                <Route path="/" element={<MainPage/>}></Route>
                <Route path="/login" element={<LoginPage/>}></Route>
                <Route path="/seeyou" element={<SeeYouPage/>}></Route>
                {/* ENG page */}
                <Route path="/en/" element={<MainPageEn/>}></Route>
                <Route path="/en/login" element={<LoginPageEn/>}></Route>
                <Route path="/en/seeyou" element={<SeeYouPageEn/>}></Route>
                <Route path="/en/my" element={<MyPageEn/>}></Route>

                ({!user.is_login && <Route path="*" element={<LoginLandingPage/>}/>})
                ({user.is_login &&
                  <>
                    <Route path="/guide" element={<GuidePage/>}></Route>
                    <Route path="/making" element={<PhotoEditorPage/>}></Route>
                    <Route path="/result" element={<ResultPage/>}></Route>
                    <Route path="/my" element={<MyPage/>}></Route>
                    <Route path="*" element={<ErrorPage/>} ></Route>

                    <Route path="/en/guide" element={<GuidePageEn/>}></Route>
                    <Route path="/en/making" element={<PhotoEditorPageEn/>}></Route>
                    <Route path="/en/result" element={<ResultPageEn/>}></Route>
                  </>
                })

              </Routes>
            </CSSTransition>
          </TransitionGroup>
          <Footer/>
          
          {/* modalsComponent */
            (modals.isShow && <Modals title={targetModalTitle} onHidden={modalCloseHandler} contents={targetModal} />)
          }
          {/* AlertComponent */
            (alertModals.isShow && <AlertModal />)
          }
          { /* GlobalLoadingComponent */
            (isLoading && <SpinerLoading/>)
          }

        </div>
  );
}

export default App;
