export const BoxVars = { 
    start : {},
    end : {
      transition: {
        duration:0,
        delayChildren:0.1,
        staggerChildren:0.2
      }
    }
  }
  export const BoxVars2 = { 
    start : {},
    end : {
      transition: {
        duration:0,
        delayChildren:0.2,
        staggerChildren:0.3
      }
    }
  }
  export const CircleVars = {
    start : { opacity:0, y:30 },
    end: {
      opacity:1, y:0,
      transition: {
        type:"tween"
      }
    },
  }
  export const WidthVars = {
    start : { opacity:0, width:0 },
    end: {
      opacity:.5,
      width: '100%',
      transition: {
        type:"tween"
      }
    },
  }