
import { styled } from 'styled-components';
import { motion } from 'framer-motion';

import ImageUploadToCropButton from '@components/ImageUploadToCropButton';
import { ExampleBox, ExampleBoxThreeContainer, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';
import { BoxVars, BoxVars2, CircleVars, WidthVars } from '@/styles/animAssets';

import sample_no from '@assets/sample_no.png';
import sample_ok from '@assets/sample_ok.png';

import sample_ok1 from '@assets/sample-good1.png';
import sample_ok2 from '@assets/sample-good2.png';
import sample_ok3 from '@assets/sample-good3.png';
import sample_no1 from '@assets/sample-bad1.png';
import sample_no2 from '@assets/sample-bad2.png';
import sample_no3 from '@assets/sample-bad3.png';
// ================================================================================
// styled

const GuideInfo = styled(motion.p)`
  font-weight: 400 !important; font-size: 23px;
`;

const BgUnderText = styled.span`
  position: relative; display: inline-block; font-weight: 700; padding: 0 2px; overflow: hidden; top:8px; position: relative;
  & > a { z-index:1; position:relative; font-size: 23px;}
  & > span {background-color:#23856D; position:absolute; width:100%; left:0; height:7px; bottom:6px; z-index:0;}
`;

const Guide = styled.p`
  font-weight: 700;
`;

const ExampleBoxItem = styled(ExampleBox)<{$isOkay:Boolean}>`
  position: relative;
   /* border: 4px solid ${({$isOkay})=> $isOkay? `#006f57`:`#BB0B0B`}; */
  & > img { position:absolute; bottom:3px; right:3px;}
`
// ================================================================================

// ================================================================================

function PhotoEditorMain() {
    const bgUnderLine = (text: string) => {
        return <BgUnderText>
          <motion.span variants={WidthVars}></motion.span>
          <motion.a variants={CircleVars}>{text}</motion.a>
        </BgUnderText>
      }
  return (
    <motion.div>
        <h2>사진 선택</h2>
        <Guide>
          AI가 감지할 수 있는 사진 속 모든 사람이<br/>
          행복한 <GreenText>크리스마</GreenText><RedText>쮸</RedText>를 날릴 수 있어요!
        </Guide>
        <motion.div variants={BoxVars} initial="start" animate="end">
          <ExampleBoxThreeContainer>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok1}><img src={sample_ok} alt='잘찍은 사진 예시1번'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok2}><img src={sample_ok} alt='잘찍은 사진 예시2번'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok3}><img src={sample_ok} alt='잘찍은 사진 예시3번'/></ExampleBoxItem>
          </ExampleBoxThreeContainer>
          <ExampleBoxThreeContainer>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no1}><img src={sample_no} alt='잘못된 사진 예시1번'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no2}><img src={sample_no} alt='잘못된 사진 예시2번'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no3}><img src={sample_no} alt='잘못된 사진 예시3번'/></ExampleBoxItem>
          </ExampleBoxThreeContainer>
        </motion.div>
        <GuideInfo variants={BoxVars2} initial="start" animate="end">
            사진 속 인물이 {bgUnderLine('정면')}을 바라볼수록 좋아요<br/>
            {bgUnderLine('얼굴을 가리는 물체')}가 없어야 해요<br/>
            {bgUnderLine('너무 작게 나온 얼굴')}은 AI가 알아볼 수 없어요<br/>
            AI의 특성상 다소 {bgUnderLine('어색한 결과물')}이 나올 수도 있어요
        </GuideInfo>
        <FooterBtnWrapper>
          <ImageUploadToCropButton text={'사진을 찾아볼게요'} isGreenBg />
          <a>*크리스마스 키스 만들기는 계정당 5번만 진행할 수 있어요</a>
        </FooterBtnWrapper>
      </motion.div>
  );
}

export default PhotoEditorMain;