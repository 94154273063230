import styled from "styled-components";
import { Spinners } from '@/styles/main';
import { SITE_LANG } from "@/Data";
// ================================================================================
// props

// ================================================================================
// styled
const Loading = styled.div`
  background-color: rgba(255,255,255,0.7); position: fixed; top:0; left:0; width:100%; height:100%;
  display: flex; align-items: center; justify-content: center; flex-direction: column;
  & > p { color:black; font-size:20px; margin-top:20px;} z-index: 999;
`;
// ================================================================================
// styled
const SpinerLoading = () => {

    return (
        <Loading>
            <Spinners/>
            <p>{SITE_LANG === 'en'?'Processing...':'작업이 진행중입니다.'}</p>
        </Loading>
    );
}



export default SpinerLoading