import { styled } from 'styled-components';

import NavLinkItem from '@components/NavLinkItem';
import { BodyEn, ExampleBox, ExampleBoxTwoContainer, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';

import mainBg from '@assets/main-bg.png';
import thumbTopSnow from '@assets/main-thumb-top-snow.png';
import thumbTopSnow2 from '@assets/main-thumb-top-snow2.png';
import { BoxVars, CircleVars } from '@/styles/animAssets';
import main1 from '@assets/sample_main1.png';
import main2 from '@assets/sample_main2.png';

import { useNavigate } from 'react-router-dom';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { useRecoilState } from 'recoil';
import { verifyToken } from '@/apis/users';
import { userData } from '@/atoms';
import { IS_LOCAL_TEST, MAIN_ROUTE } from '@/Data';

// ================================================================================
// styled

const MainContentsBody = styled(BodyEn)`
  background-image: url(${mainBg}); background-size: 100%; background-position: 0 bottom; background-repeat: no-repeat;
  height: 1360px; background-color: #ededed;
  h2 > span { font-size:60px !important;}
`;

const FooterLineBtn = styled(NavLinkItem)`
  & button { border: 3px solid white !important; }
`;

const SnowTopExampleBox = styled(ExampleBox)`
  position: relative;
  & > img { position:absolute; top:-20px; left:0; width:106%; left:-3%; }
`;

const MainExampleContainer = styled(ExampleBoxTwoContainer)`
  margin-bottom: 355px;
`

// ================================================================================

// ================================================================================
function Main() {
  const navigate = useNavigate();
  const {run} = useAsyncCallback(verifyToken);
  const [user, setUser] = useRecoilState(userData);
  

  const startStep = () => {
    // 로컬스토리지에 token key가 있는지 확인한경우 유효토큰검증, 토큰key가 없으면 일단 진행
    if(IS_LOCAL_TEST){
      navigate(MAIN_ROUTE+'guide');
      return;
    }


    const _token = localStorage.getItem('token');
    if(_token){
      run().then((res:any)=>{
        if(!res){
          localStorage.setItem('token', '');
          navigate(MAIN_ROUTE+'login');
        }else{
          setUser({...user, is_login:true});
          navigate(MAIN_ROUTE+'guide');
        }
      });
    }else{
      navigate(MAIN_ROUTE+'login');
    }
  }

  return (
        <MainContentsBody>
          <h2><GreenText>Kiss</GreenText><RedText>mas</RedText></h2>
          
          <p>This Christmas, <br/>
          blow a <GreenText style={{fontWeight:'bold'}}>Christmas</GreenText> <RedText style={{fontWeight:'bold'}}>kiss</RedText><br/>
          to your loved ones with all your heart!
          </p>
          <MainExampleContainer variants={BoxVars} initial="start" animate="end">
            <SnowTopExampleBox variants={CircleVars} $imgUrl={main1}>
              <img src={thumbTopSnow} alt="thumbTopSnow" />
            </SnowTopExampleBox>
            <SnowTopExampleBox variants={CircleVars} $imgUrl={main2}>
              <img src={thumbTopSnow2} alt="thumbTopSnow" />
            </SnowTopExampleBox>
          </MainExampleContainer>
          <FooterBtnWrapper>
            <FooterLineBtn onClick={startStep} child={"Send a Christmas Kiss"} isButton></FooterLineBtn>
            {/* <FooterLineBtn whileTap={{ scale: 0.9 }} onClick={startStep}>크리스마스 키스 보내기</FooterLineBtn> */}
          </FooterBtnWrapper>
        </MainContentsBody>
  );
}

export default Main;
