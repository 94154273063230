import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
// import CatInfoAddModal from "../Modals/CatInfoAddModal";
import { motion } from "framer-motion";

// ================================================================================
// props

interface ModalProps {
    title?: string,
    onHidden?: Function,
    contents: JSX.Element,
}

// ================================================================================
// styled
const ModalCover = styled.div<{$isOriginModalStyle:Boolean}>`
  position: fixed; top:0; left: 0; width: 100%; z-index: 999;

  ${({$isOriginModalStyle})=>$isOriginModalStyle? 
    `display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.7);` : 
    `height:100%; background-color:white;`
    }
`;

const ModalContainer = styled(motion.div)<{$isOriginModalStyle:Boolean}>`
    width: 100%; height:100%; border-radius: 20px; padding: 10px 20px;
    box-sizing: border-box; position: relative; padding-top: 0;
    background-color: #fff;

    ${({$isOriginModalStyle})=>`
        border-radius: ${$isOriginModalStyle?'20px':'0'};
        height: ${$isOriginModalStyle?'auto':'100%'};
        width: ${$isOriginModalStyle?'700px':'100%'};
    `}

    h2 { font-size:30px;}
    &>span { 
        display: flex; margin-bottom:20px; justify-content: space-between;
        p { width:200px; font-size:25px; }
        input[type='text'],select { width:350px; box-sizing:border-box; text-indent:20px; }
        div { display:flex; align-items:center;}
        input[type='number'] { width:100px; text-align:center;}
        a { font-size:25px; margin-left:10px;}
    }
    /* button { padding:30px 0; font-size:25px; border:0; margin-bottom:10px; font-weight:600;} */
`;
const Title = styled.div`
    z-index: 100; padding: 20px;
    & > svg { position: absolute; top:20px; right:20px; cursor: pointer;}
`


// ================================================================================
// styled
const Modals = (props:ModalProps) => {
    // const isToggle = useRecoilValue(isToggleAtom);
    const closeModalClickHandler = (e:any)=>(props.onHidden)? props.onHidden():null;

    return (
        <ModalCover $isOriginModalStyle={props.title?true:false}>
            <ModalContainer
                $isOriginModalStyle={props.title?true:false}
                as={motion.div} 
                initial={{ opacity: 0, marginTop: 100 }}
                animate={{ opacity: 1, marginTop: 0 }}
                exit={{ opacity: 0, scale: 0.8 }}>
                {props.title && <>
                    <Title>
                        {/* <h2>{props.title}</h2> */}
                        <AiOutlineClose onClick={closeModalClickHandler} onTouchEnd={closeModalClickHandler} size={42}/>               
                    </Title>
                </>}
                
                {props.contents}
            </ModalContainer>
        </ModalCover>
    );
}



export default Modals