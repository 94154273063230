
import { styled } from 'styled-components';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { AnimType } from '@/types/Types';
import { pageAnim } from '@/atoms';
import { GreenButton } from '@/styles/main';
import { SITE_LANG } from '@/Data';

// ================================================================================
// styled
const NavLinkDom = styled.a`
  cursor: pointer;
`;

const EnGreenButton = styled(GreenButton)<{$isEn:boolean}>`
${({$isEn})=> $isEn? `font-size: 25px;` : ``}
`

type NavItemProps = {
    to?: string,
    onClick?: Function,
    child?: string,
    // animType?: AnimType,
    isButton?: boolean,
}
// ================================================================================

// ================================================================================

function NavLinkItem(props:NavItemProps) {
    const navigate = useNavigate();
    const [anim,setAnim] = useRecoilState(pageAnim);

    const clickHandler = (e:any) => {
        e.preventDefault();
        setAnim('push');

        if(props.to){
          navigate(props.to);
        }else if(props.onClick){
          props.onClick();
        }
    }

  return (
    <div>
    {(!props.isButton)?
      <NavLinkDom onClick={clickHandler}>{props.child}</NavLinkDom>:
      <EnGreenButton $isEn={SITE_LANG==='en'} whileTap={{ scale: 0.9 }} onClick={clickHandler}>{props.child}</EnGreenButton>}
    </div>
  );
}

export default NavLinkItem;