
import { APP_DATA, SERVICE_DATA, SITE_LANG } from "@/Data";
declare global {
    interface Window {
        Kakao: any;
    }
}
const { Kakao } = window;

export type ISnsTagetType = 'kakao'|'x'|'facebook'|'line'

export const handleCopyClipBoard = (text: string) => {
    const $textarea = document.createElement('textarea');
    document.body.appendChild($textarea);
    $textarea.value = text;
    $textarea.select();
    document.execCommand('copy');
    document.body.removeChild($textarea);
};
 
export const snsShareHandler = ($target:ISnsTagetType, $name:string|undefined, $id:string) => {
    const userPageUrl = SERVICE_DATA.SHARE_ROOT+$id;
    const _name = $name? (SITE_LANG === 'kr')? $name+'님이 ': $name : '';

    switch($target){
        case 'kakao': kakaoShareHandler(_name,userPageUrl); break;
        case 'x': xShareHandler(_name,userPageUrl); break;
        case 'facebook': fbShareHandler(userPageUrl); break;
        case 'line': lineShareHandler(userPageUrl); break;
    }
};

const kakaoShareHandler = (name:string|undefined, userUrl:string) => {

    Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
            title: APP_DATA.SHARE_TITLE,
            description: name+APP_DATA.SHARE_DESCRIPTION,
            imageUrl: APP_DATA.SHARE_IMAGE,
            link: {
                mobileWebUrl: userUrl
            }
        },
        buttons: [
            {
                title: (SITE_LANG === 'kr')? '크리스마쮸 받으러가기':'Get a Kissmas',
                link: {
                    mobileWebUrl: userUrl,
                },
            },
        ],
    });
};

const xShareHandler = (name:string|undefined, userUrl:string) => {
    window.open(`
        https://twitter.com/intent/tweet?
        text=${APP_DATA.SHARE_TITLE+" "+name+APP_DATA.SHARE_DESCRIPTION}
        &url=${userUrl}
        &hashtags=${APP_DATA.SHARE_HASHTAG}`);
}
const fbShareHandler = (userUrl:string) => window.open(`https://www.facebook.com/sharer/sharer.php?u=${userUrl}`);
const lineShareHandler = (userUrl:string) => window.open(`https://social-plugins.line.me/lineit/share?url=${userUrl}`);