import styled from "styled-components";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { alertModal } from "@/atoms";
import { SITE_LANG } from "@/Data";

// ================================================================================
// props

// ================================================================================
// styled
const ModalCover = styled.div`
    width: 100%; height:100%; background-color: rgba(0,0,0,0.7); position: fixed; top:0; left: 0; z-index: 9999;
    display: flex; justify-content: center; align-items: center;

`;

const ModalContainer = styled(motion.div)<{$isEn:boolean}>`
    background-color: white; width: 535px; border-radius: 20px;;

    & > p { 
        text-align:center; width:100%; height:auto; 
        ${({$isEn})=>$isEn? `padding: 50px 10px; font-size: 25px; `:`padding: 50px 40px; font-size: 25px; `}
        display:flex; justify-content:center; align-items:center; box-sizing:border-box;
        flex-direction: column;

        & > svg { margin-bottom: 20px; }
    }

    &>span { width:100%; height:104px; display:flex; justify-content: center; border-top:1px solid #D9D9D9; }
    &>span>button { background-color:inherit; border:none; height:100%; color:#006F57; font-size: 30px; font-weight: bold; width:100%; }
    &>span>button:nth-child(2) { border-left:1px solid #D9D9D9; }
`;

const IconContainer = styled.div`
    width: 100%; display: flex; justify-content: center; align-items: center; padding: 50px 0;
`

// ================================================================================
// styled
const AlertModal = () => {
    const [alertModals, setAlertModal] = useRecoilState(alertModal);

    const compClickHandler = (e:any) => {
        if(alertModals.compFn) alertModals.compFn();
        setAlertModal({isShow:false, compFn:undefined, cancleFn:undefined, text:undefined, compText:undefined});
    };
    const cancleClickHandler = (e:any) => {
        setAlertModal({isShow:false, compFn:undefined, cancleFn:undefined, text:undefined, compText:undefined});
    };

    return (
        <ModalCover>
            <ModalContainer 
                $isEn={SITE_LANG === 'en'}
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.2, ease: "backInOut" }}
                >
                {/* <IconContainer>
                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.5 0C9.225 0 0 9.225 0 20.5C0 31.775 9.225 41 20.5 41C31.775 41 41 31.775 41 20.5C41 9.225 31.775 0 20.5 0ZM20.5 36.9C11.4595 36.9 4.1 29.5405 4.1 20.5C4.1 11.4595 11.4595 4.1 20.5 4.1C29.5405 4.1 36.9 11.4595 36.9 20.5C36.9 29.5405 29.5405 36.9 20.5 36.9ZM29.9095 11.439L16.4 24.9485L11.0905 19.6595L8.2 22.55L16.4 30.75L32.8 14.35L29.9095 11.439Z" fill="#006F57"/>
                    </svg>
                </IconContainer> */}
                <p>
                    {alertModals.icon && <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.5 0C9.225 0 0 9.225 0 20.5C0 31.775 9.225 41 20.5 41C31.775 41 41 31.775 41 20.5C41 9.225 31.775 0 20.5 0ZM20.5 36.9C11.4595 36.9 4.1 29.5405 4.1 20.5C4.1 11.4595 11.4595 4.1 20.5 4.1C29.5405 4.1 36.9 11.4595 36.9 20.5C36.9 29.5405 29.5405 36.9 20.5 36.9ZM29.9095 11.439L16.4 24.9485L11.0905 19.6595L8.2 22.55L16.4 30.75L32.8 14.35L29.9095 11.439Z" fill="#006F57"/>
                    </svg>}
                    {alertModals.text??""}
                </p>
                <span>
                    {alertModals.cancleFn && <button onClick={cancleClickHandler}>{(SITE_LANG === 'kr'?'아니오':'cancel')}</button>}
                    <button onClick={compClickHandler}>{alertModals.compText??(SITE_LANG === 'kr'?'확인':'OK')}</button>
                </span>
            </ModalContainer>
        </ModalCover>
    );
}



export default AlertModal