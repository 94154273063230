import { styled } from 'styled-components';
import { useEffect } from 'react';

import { Body, GreenText, RedText } from '@/styles/main';
import { API_SERVER_URL, SITE_LANG } from '@/Data';

import fbLoginBtn from '../assets/login_btn_fb.png';
import kkLoginBtn from '../assets/login_btn_kakao.png';
import nvLoginBtn from '../assets/login_btn_naver.png';
import ggLoginBtn from '../assets/login_btn_google.png';


// ================================================================================
// styled
const BodyWrapper = styled(Body)`
  & > p { margin-bottom: 140px;}
`;

const LoginForms = styled.div`
  width: 100%; text-align: center;
  /* & > button { width:95%; padding: 20px; border-radius:20px; background-color:black; color:white; font-size: 30px;} */
  & img { width:390px; margin-bottom:30px; cursor: pointer;}
`;

// ================================================================================

// ================================================================================

function LoginPage() {
  
  const loginBtnSocial = (provider:'google'|'naver'|'kakao'|'facebook') => {
    localStorage.setItem('token_provider', provider);
    localStorage.setItem('language', SITE_LANG);
    window.location.href = `${API_SERVER_URL}api/auth/login/${provider}`
  };
  
  useEffect(()=>{
    // if(user.is_login) navigate('/', { replace: true });
  },[]);

  return (
    <BodyWrapper>
      <h2>LOGIN</h2>
      <p>계정을 생성하면, <br/>
        다섯 개의 <GreenText>크리스마스</GreenText> <RedText>키스</RedText>를 무료로 만들 수 있어요!
      </p>
      
      <LoginForms>
        <a onClick={()=>loginBtnSocial('google')}><img src={ggLoginBtn}/></a>
        <img onClick={()=>loginBtnSocial('naver')} src={nvLoginBtn}/>
        {/* <img src={fbLoginBtn}/> */}
        <img onClick={()=>loginBtnSocial('kakao')} src={kkLoginBtn}/>
      </LoginForms>
    </BodyWrapper>
  );
}

export default LoginPage;
