
import { useEffect } from 'react';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE } from '@/Data';

// ================================================================================
// styled
const Body = styled.div`
  background-color: white; min-height: 100%; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center;
`;

// ================================================================================

// ================================================================================


function LoginLandingPage() {
  const navigator = useNavigate();

  useEffect(()=>{
    navigator(MAIN_ROUTE+'login', { replace: true });
  },[]);

  return (
      <Body>
        Login Landing Page!
      </Body>
  );
}

export default LoginLandingPage;
