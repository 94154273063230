import { useCallback, useEffect, useState } from "react"

interface IUseAsyncReturn<DataType> {
    data: DataType | undefined
    isLoading: boolean
    error: unknown
    reload: () => void
}

interface IUseAsyncCallbackReturn<DataType> {
    data: DataType | undefined
    isLoading: boolean
    error: unknown
    run: Function
}

export function useAsync<ArgsType, DataType>(
    func: Function,
    params: Array<ArgsType> | undefined = undefined
): IUseAsyncReturn<DataType> {
    const [reloadKey, reloadFunc] = useState(true)
    const [data, setData] = useState<undefined | DataType>()
    const [isLoading, toggleLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error>()
    const reload = useCallback(() => reloadFunc(!reloadKey), [])
    const [originParams, setParams] = useState(params)
    useEffect(() => {
        if (JSON.stringify(originParams) != JSON.stringify(params))
            setParams(params)
    }, [params])
    useEffect(() => {
        ;(async () => {
            toggleLoading(true)
            try {
                let _data
                if (params instanceof Array) _data = await func(...params)
                else _data = await func()
                setData(_data.data)
            } catch (error) {
                let message
                if (error instanceof Error) {
                    message = error.message
                    setError(error)
                } else message = String(error)
                reportError({ message })
            } finally {
                toggleLoading(false)
            }
        })()
    }, [originParams, reloadKey])

    return {
        data: data,
        isLoading: isLoading,
        error: error,
        reload: reload,
    }
}

export function useAsyncCallback<ArgsType, DataType>(
    func: Function,
    params: Array<ArgsType> | undefined = undefined
): IUseAsyncCallbackReturn<DataType> {
    const [data, setData] = useState()
    const [isLoading, toggleLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error>()

    // const reload = useCallback(() => reloadFunc(reloadKey +1), [])
    const run = useCallback(async (...params: Array<ArgsType>) => {
        toggleLoading(true)
        let result
        try {
            if (params instanceof Array) result = await func(...params)
            else result = await func()
            setData(result)
        } catch (error) {
            let message
            if (error instanceof Error) {
                message = error.message
                setError(error)
            } else message = String(error)
            console.error(error)
            reportError({ message })
        } finally {
            toggleLoading(false)
        }
        return result
    }, [])

    return {
        data: data,
        isLoading: isLoading,
        error: error,
        run: run,
    }
}
