import { MAIN_ROUTE } from "@/Data";
import api from "@/hooks/extended-axios"
// import { API_SERVER_URL } from "@/Data"

export async function getSocialLogin(serviceType: 'google' | 'naver' | 'kakao' | 'facebook') {
    const url = `${MAIN_ROUTE}api/auth/login/${serviceType}`
    return api.get(url)
}

export async function verifyToken() {
    const url = `${MAIN_ROUTE}api/auth/verifyToken`
    return api.get(url);
}

export async function getTicketCnt() {
    const url = `${MAIN_ROUTE}api/file/ticket-cnt`
    return api.get(url);
}

export async function myInfo() {
    const url = `${MAIN_ROUTE}api/users/me/detail`
    return api.get(url);
}

export async function signOut() {
    const url = `${MAIN_ROUTE}api/users/me`
    return api.delete(url,{});
}

export async function getNickName() {
    const url = `${MAIN_ROUTE}api/users/me`
    return api.get(url);
}
