import { MAIN_ROUTE, SITE_LANG } from "@/Data"
import { stat } from "fs"
import Swal from "sweetalert2"

const projectException = (error: IError) => {
    // if {
    //     Swal.fire({ icon: "error", text: "중복된 콘텐츠 명입니다." })
    //     return true
    // }

    return false
}

interface IError {
    message: string
    response: {
        status: number
        data: {
            detail: string
            [key: string]: any
        }
    }
}

interface IErrorCaseItem {
    [key: string]: string
}
interface IErrorCase {
    3: IErrorCaseItem
    4: IErrorCaseItem
    5: IErrorCaseItem
}

const ERROR_3XX: IErrorCaseItem = {
    
}

const ERROR_4XX: IErrorCaseItem = {
    "Token is invalid or has expired": "토큰이 만료되었습니다",
    'Not authenticated': "권한이 없습니다.",
    "You are not logged in": "로그인이 필요합니다",
}

const ERROR_5XX: IErrorCaseItem = {
    
}

const ErrorCase: IErrorCase = {
    3: ERROR_4XX,
    4: ERROR_4XX,
    5: ERROR_5XX,
}

const handleError = (error: IError) => {
    if (error.message === "Network Error") {
        Swal.fire({ text: (SITE_LANG==='kr'?"서버와 연결되지 않았습니다.":"Network error"), icon: "error" })
        
        return
    }
    let message: string;

    console.log(error)
    if (!error.response.data) {
        console.error(error.response)
        Swal.fire({ text: "404 NotFound", icon: "error" })
        return
    }
    // 완전 예외
    if (projectException(error)) return

    const {
        status,
        data: { detail },
    } = error.response
    console.log(status, detail);
    const firstCode = Number(status.toString()[0]);

    if (status === 401) {
        localStorage.removeItem("token");
        message = (SITE_LANG==='kr')?"로그인이 필요합니다":'Please log in';
        Swal.fire({ text: message, icon: "error", didClose() {
            window.location.replace(MAIN_ROUTE+'login');
        }, })
        return;
    } else if (status === 508) {
        message = (SITE_LANG==='kr')?"AI 서버에서 사진을 처리하지 못했습니다.\n다른사진을 이용해주세요.":`AI server failed to process the photo.\nPlease use another photo.`;
        Swal.fire({ text: message, icon: "error", didClose() {
            window.location.replace(MAIN_ROUTE+'making');
        }, })
        return;
    } else if (
        (firstCode === 4) &&
        detail in ErrorCase[firstCode]
    ) {
        message = ErrorCase[firstCode][detail]
    } else if (firstCode === 5) {
        message = (SITE_LANG==='kr')?`서버와의 통신이 원할하지 않습니다<br/>잠시후 다시 시도해주세요.<br/>(${status} : ${detail})`: `Server communication is not working properly.<br/>Please try again later.<br/>(${status} : ${detail})`;
    } else {
        console.error(status, detail)
        message = (SITE_LANG==='kr')?`서버와의 통신이 원할하지 않습니다<br/>잠시후 다시 시도해주세요.<br/>(${status} : ${detail})`: `Server communication is not working properly.<br/>Please try again later.<br/>(${status} : ${detail})`;
    }

    Swal.fire({ html: message, icon: "error" })
}

export default handleError
