import { styled } from 'styled-components';
import { useRecoilState } from 'recoil';
import imageCompression from 'browser-image-compression';

import { modal,originImage, isGlobalLoading } from '../atoms';
import { GreenButton, GreenLineButton } from '@/styles/main';
import { SITE_LANG } from '@/Data';

// import { useAsyncCallback } from '@/hooks/extended-axios/hooks';
// import { fileUpload } from '@/apis/process';

// ================================================================================
// styled

const ImageSelectBtnIsLine = styled(GreenLineButton)<{$isEn:boolean}>`
  cursor: pointer;
  ${({$isEn})=> $isEn? `font-size: 25px; padding:30px 15px;` : ``}

  & input { opacity:0; position:absolute; top:0; left:0; width:100%; height:100%; }
`
const ImageSelectBtn = styled(GreenButton)<{$isEn:boolean}>`
  cursor: pointer; z-index: 2;
  ${({$isEn})=> $isEn? `font-size: 25px;` : ``}

  & input { opacity:0; position:absolute; top:0; left:0; width:100%; height:100%; }
`

// ================================================================================

// ================================================================================
type ImageUploadToCropButtonProps = {
    onComped?: () => void;
    text: string;
    isGreenBg?: boolean;
};


function ImageUploadToCropButton(props:ImageUploadToCropButtonProps) {
  const [isLoading, setLoading] = useRecoilState(isGlobalLoading);
  const [originImg, setOriginImage] = useRecoilState(originImage);
  const [modals, setModal] = useRecoilState(modal);
  // getTicketCnt

  const handleFileOnChange = async (e:any) => {
    let file = e.target.files[0];	// 입력받은 file객체
    setOriginImage('');
    setLoading(true);
    
    // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
    const options = { 
      maxSizeMB: 5, 
      maxWidthOrHeight: 1400
    }
    
    try {
      const compressedFile = await imageCompression(file, options);
      
      // resize된 이미지의 url을 받아 fileUrl에 저장
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then(result => {
        setOriginImage(result);
        setModal({isShow:true, target:'crop'});
        setLoading(false);
      })
    } catch (error) {
      console.log(error);
    }
  }

  return (
      (props.isGreenBg)?
        <ImageSelectBtn $isEn={SITE_LANG==='en'} whileTap={{ scale: 0.9 }}>
            {props.text}
            <input type='file'
                accept='image/*' 
                name='uploadImage' 
                onChange={handleFileOnChange}></input>
        </ImageSelectBtn>:
        <ImageSelectBtnIsLine $isEn={SITE_LANG==='en'} whileTap={{ scale: 0.9 }}>
          {props.text}
          <input type='file'
              accept='image/*' 
              name='uploadImage' 
              onChange={handleFileOnChange}></input>
      </ImageSelectBtnIsLine>
  );
}

export default ImageUploadToCropButton;
