
export const IS_LOCAL_TEST = false;

export const SITE_LANG: 'en'|'kr' = window.location.pathname.indexOf('/en') > -1 ? 'en' : 'kr';
export const API_SERVER_URL = process.env.REACT_APP_SERVER_URL??'https://kissmas.me/';
export const MAIN_ROUTE = (SITE_LANG === 'kr')?'/':'/en/';
export const APP_DATA = (SITE_LANG === 'kr')?{
    APP_URL: API_SERVER_URL,
    SHARE_TITLE : `크리스마쮸`,
    SHARE_DESCRIPTION : '크리스마스 선물을 보냈습니다 🎁',
    SHARE_IMAGE: 'https://s3.ap-northeast-2.amazonaws.com/test.dob.world/kissmas/share.png',
    SHARE_HASHTAG: '크리스마쮸,kissmas',
}:{
    APP_URL: API_SERVER_URL+'en/',
    SHARE_TITLE : `kissmas`,
    SHARE_DESCRIPTION : 'sent you a Christmas present 🎁',
    SHARE_IMAGE: 'https://s3.ap-northeast-2.amazonaws.com/test.dob.world/kissmas/share.png',
    SHARE_HASHTAG: 'kissmas',
}
export const SERVICE_DATA = {
    DOB_URL: 'https://dob.world',
    SHARE_ROOT: `${API_SERVER_URL+(SITE_LANG === 'kr'?'':'en/')}api/file/share/`,
    TERMS_OF_SERVICE_URL: `${API_SERVER_URL}${(SITE_LANG === 'kr')?'':'en/'}api/auth/terms-of-service`,
    PRIVACY_POLICY_URL: `${API_SERVER_URL}${(SITE_LANG === 'kr')?'':'en/'}api/auth/privacy-policy`,
}