import { motion } from "framer-motion";
import styled from "styled-components";

export const Body = styled.div`
  padding: 45px; padding-top:85px; box-sizing: border-box; min-height: 100vh; background-color: white;
  
  h2 { margin:52px 0 45px; font-size: 60px;}
  & > p, & span { font-size: 30px; }
  & > p { line-height: 50px; font-weight:700; }
`;
export const BodyEn = styled(Body)`
  & > p { font-weight:500; line-height:40px; }
`;

export const Spinners = styled.span`
    width: 60px;
    height: 60px;
    border: 7px solid #d9d9d9;
    border-bottom-color: #006F57;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;


    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
`;

export const BaseImageBox = styled(motion.div)`
  background-color: #D9D9D9; border-radius: 10px; background-position: center; background-repeat: no-repeat; background-size: 100%;
`;

export const GreenText = styled.span` color: #006F57; `
export const RedText = styled.span` color: #BB010B; `

export const FooterBtnWrapper = styled.div`
  width: 100%; text-align: center; z-index: 2; margin-top: 20px; margin-top: 57px;
  /* position: fixed; bottom: 58px; left: 0; */

  &>a { width:100%; display:block; color:#3C3C3C; font-weight:400; font-size: 20px; margin-top:20px; } 
  /* position:absolute; bottom:-40px; */
`;

export const GreenButton = styled(motion.button)`
  padding: 30px 35px; font-size: 22px; border: 0; margin-bottom: 10px; font-weight: 700; font-size: 30px; min-width: 320px; z-index: 2;
  background-color: #006F57; color: #FAF8F8; border-radius: 100px; position: relative; margin: 0 10px; border: 3px solid #ddd;
`;

export const GreenLineButton = styled(GreenButton)`
  background-color: white; color: #006F57 !important; border: 4px solid #006F57;
`;

export const ExampleBoxThreeContainer = styled.div`
  width: 100%; display: flex; justify-content: space-between; margin-bottom: 25px;
  & > div { width: 203px; height: 203px; }
`
export const ExampleBoxTwoContainer = styled(motion.div)`
  width: 661px; display: flex; justify-content: space-between; margin: 60px 0; position: relative;
  & > div { width: 314px; height: 314px; }
`;
export const ExampleBox = styled(BaseImageBox)<{$imgUrl?:string}>`
  background-image: url(${({$imgUrl}) =>`${$imgUrl}`});
  width: 314px; height: 314px;
`;