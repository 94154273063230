import { MAIN_ROUTE } from "@/Data";
import api from "@/hooks/extended-axios"
// import { API_SERVER_URL } from "@/Data"

export async function fileUpload(files: any) {
    const url = `${MAIN_ROUTE}api/file/upload`;
    return api.post(url, files, 'multipart/form-data')
}

export type IProcessData = {
    idxs: string[],
    divs: Object[]
}
export async function cropImageToZzu(id: string, data: IProcessData) {
    const url = `${MAIN_ROUTE}api/file/process-faces/${id}`;
    return api.post(url, data)
}

export async function getImages(url:string) {
    return api.get(url, null, '', 'blob');
}

export async function setShareIdx(id:string, idx:string) {
    console.log(id,idx);
    const url = `${MAIN_ROUTE}api/file/share/${id}`;
    const _data = {'frame_idx':idx}
    return api.put(url, _data);
}
