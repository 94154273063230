
import NavLinkItem from '@components/NavLinkItem';
import { Body, ExampleBox, ExampleBoxTwoContainer, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';
import { BoxVars, CircleVars } from '@/styles/animAssets';
import { styled } from 'styled-components';

import guide1 from '../assets/sample_guide_before.png';
import guide2 from '../assets/sample_guide_after.png';
import { useAsyncCallback } from '@/hooks/extended-axios';
import { getNickName, getTicketCnt } from '@/apis/users';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { alertModal, userData } from '@/atoms';
import { IS_LOCAL_TEST } from '@/Data';
import { useState } from 'react';

// ================================================================================
// styled

// ================================================================================
const ExampleItem = styled.div<{$isBefore:Boolean}>`
  & > p { 
    width: 100%; text-align: center; margin: 5px 0; font-size:26px; font-weight: bold; 
    color:${({$isBefore})=> $isBefore? `#23856D`:`#006F57`}; 
  }
`
// ================================================================================

function GuidePage() {
  const navigate = useNavigate();
  
  const {run} = useAsyncCallback(getTicketCnt);
  const [user, setUser] = useRecoilState(userData);
  const {run:getNickNameRun} = useAsyncCallback(getNickName);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const [isProcess, setProcess] = useState<boolean>(false);

  const nickNameCheck = () => {
    if(IS_LOCAL_TEST){
      navigate('/making');
      return;
    }
    if(isProcess) return;

    setProcess(true);
    //nickName이 없으면 닉네임 입력 페이지로 이동
    if(user.nickname === '' || user.role === ''){
      getNickNameRun().then((res:any) => {
        if(res && res.status === 200){
          setUser({...user, nickname:res.data.result.nickname, role:res.data.result.role});

          nextStepTicketCheck();
        }else{
          setProcess(false);
        }
      });
    }else{
      setProcess(false);
      nextStepTicketCheck();
    }

  }

  const nextStepTicketCheck = () => {
    run().then((res:any) => {
      console.log(res);
      if(res && res.status === 200){
        const ticketCnt:number = res.data.result.ticket_cnt;
        const isShareEvent:number = res.data.result.is_shared;
        let isJoinEventCheck:boolean = (isShareEvent? ticketCnt<6 : ticketCnt<5);
        setProcess(false);

        if(isJoinEventCheck || user.role === 'admin'){
          if(user.role === 'admin') alert("admin"); 
          navigate('/making');
        }else{
          setAlert({isShow:true, text:<>크리스마쮸를 만들 수 있는<br/>횟수를 모두 소진했어요.</>});
        }
      } else {
        setProcess(false);
      }
    });
  }

  return (
        <Body>
          <h2>어떤 사진이든 좋아요</h2>
          <p>
            특별한 날, 추억의 장소에서 찍은 사진을 골라보세요<br/>귀여운 키스는 <GreenText>크리스마</GreenText><RedText>쮸</RedText>의 AI가 책임질게요!
          </p>
          <ExampleBoxTwoContainer variants={BoxVars} initial="start" animate="end">
            <ExampleItem $isBefore>
              <ExampleBox variants={CircleVars} $imgUrl={guide1}/>
              <p>Before</p>
            </ExampleItem>
            <ExampleItem $isBefore={false}>
              <ExampleBox variants={CircleVars} $imgUrl={guide2}/>
              <p>After</p>
            </ExampleItem>
          </ExampleBoxTwoContainer>
          <p>
            눈 깜짝할 사이에 만들어진 <GreenText>크리스마</GreenText><RedText>쮸</RedText><br/>
            <a>가족, 친구, 연인 소중한 모든 사람에게 마음을 전해보아요!</a>
          </p>
          <FooterBtnWrapper>
            <NavLinkItem onClick={nickNameCheck} child={'크리스마쮸 만들기 시작'} isButton></NavLinkItem>
          </FooterBtnWrapper>
        </Body>
  );
}
export default GuidePage;
