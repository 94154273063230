import { Body, GreenText, RedText } from '@/styles/main';
import { styled } from 'styled-components';
import seeyouTree from '@assets/seeyou_tree.png';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userData } from '@/atoms';
// ================================================================================
// styled
// ================================================================================
const TreeImg = styled.img`
  width: 90%; left:5%; position:relative;`
// ================================================================================

function SeeYouPage() {
  const [user, setUser] = useRecoilState(userData);
  useEffect(()=>{
    setUser({is_login:false, nickname:'', ticket_cnt:0, files:[], isDetailInit:false, role:''});
    localStorage.removeItem("token");
    localStorage.removeItem('token_provider');
  },[]);
  return (
        <Body>
          <h2>
            <GreenText>H</GreenText>
            <RedText>a</RedText>
            <GreenText>p</GreenText>
            <RedText>p</RedText>
            <GreenText>y</GreenText> <GreenText>H</GreenText>
            <RedText>o</RedText>
            <GreenText>l</GreenText>
            <RedText>i</RedText>
            <GreenText>d</GreenText>
            <RedText>a</RedText>
            <GreenText>y</GreenText>
            <RedText>!</RedText>
          </h2>
          <p>dob Studio is preparing a service<br/>
          that allows you to easily<br/>
          and quickly correct the photos containing<br/>
          your precious memories using AI technology</p>
          <TreeImg src={seeyouTree}/>
          <h3>Please look forward to it!</h3>
        </Body>
  );
}

export default SeeYouPage;
