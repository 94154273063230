import axios from 'axios';
import { motion } from 'framer-motion';
import { styled } from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Tooltip } from 'react-tooltip';

import { alertModal, makeProjectData } from '@/atoms';
import ImageUploadToCropButton from '@components/ImageUploadToCropButton';
import { Body, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';
import PhotoEditorMainEn from '@/components/PhotoEditor/PhotoEditorMainEn';
import { useAsyncCallback } from '@/hooks/extended-axios/hooks';
import { cropImageToZzu } from '@/apis/process';
import { API_SERVER_URL, IS_LOCAL_TEST, MAIN_ROUTE } from '@/Data';

// resources
import loading from '@assets/loading_en.gif';
import cookie from '@assets/cookie.gif';
import progressBar from '@assets/progress_bar.png';
import progressCover from '@assets/progress_cover.png';
import NavLinkItem from '@/components/NavLinkItem';

// ================================================================================
// styled
const SelectBox = styled(motion.div)<{$isDetected:Boolean, $size:Number}>`
  ${({$size}) => $size? `width:${$size}px; height:${$size}px;` : 'width:660px; height:660px;'}

  overflow: hidden; border-radius: 30px; left: 50%; margin-left: -330px;
  position: relative; box-sizing: border-box;
  & > img { width:100%; opacity:0.5;}
  ${({$isDetected})=> $isDetected?``:`border: 6px solid #bb010b;`}
`;

const FaceDetectRect = styled(motion.div)<{$left:number, $top:number, $width:Number, $height:Number, $isSelect:Boolean, $image:String, $ratio:Number}>`
  border:6px dashed #bb010b; position:absolute; min-width: 100px; min-height: 100px; cursor: pointer;
  
  ${({$left,$top,$width,$height, $isSelect}) => `
    left:${$left}px; top:${$top}px; width:${$width}px; height:${$height}px;

    & > div { ${$isSelect? 'opacity:1' : 'opacity:0;'} }
  `};
  & > div { 
    width:100%; height:100%; 
    background-image: url(${({$image}) =>`${$image}`});
    background-position: -${({$left}) =>`${($left+6)}px`} -${({$top}) =>`${($top+6)}px`}; background-size: 660px 660px;
  }
`;

// picSize
const GuideInfo = styled.p`font-weight: 400 !important; margin:0; font-size: 18px !important; margin-bottom: 30px;`;

const FaceDetectFailTitle = styled.h4 `
  display: flex; align-items: center; 
  & > svg { margin-left: 5px; top:2px; position: relative; }
`
const TooltipBtn = styled.span`
  display: inline-block; width: 29px; height: 29px; text-align: center; font-size: 21px !important; background-color: #006f57; color:white; border-radius: 100px; line-height: 29px;
  font-weight: bold; margin-left: 5px; box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.2);
  & > p { padding:0; margin:0; }
`
const Step = styled.p`font-size: 18px !important; margin:5px 0;`;

const EditorResultFooterBtnWrapper = styled(FooterBtnWrapper)<{$isDouble?:boolean}>`  
  margin-top: 0px; display: flex; align-items: center;
  justify-content: ${({$isDouble})=> $isDouble?`space-between`:`center`};

  &> button { margin:0;}
`;


const ProgressContainer = styled.div`
  width:750px; height:100%; background-color:rgba(255,255,255,1); position: fixed; top:0; left:50%; transform: translateX(-50%);
  display: flex; justify-content: center; align-items: center; z-index:100; flex-direction: column;
  
  div {
    position:relative; width: 80%; background-color: #72b59a; height: 85px; overflow: hidden;;

    img { position:absolute; top:0; left:0; width:100%; }
    .bar { left: -100%; }
  }
`;
const CookieMotionSpan = styled(motion.span)`
  width:150px; display:block; position: relative; top: -120px; left: -40%;
  & > div { 
    position:absolute; width:300px; height:80px; left:-300px; bottom:15px;
    font-weight:bold; text-align:right; font-size:20px; background-color:inherit; color: #006F57;
  };
`;

// ================================================================================
// functions
declare global {
  interface Window {
    resolveLocalFileSystemURL: any;
  }
}
  
const getImgRun = (imageUrl: string): Promise<any> => {
  const token: string | null = localStorage.getItem("token");
  return axios.get(imageUrl, { responseType: 'arraybuffer', headers: { 'Authorization': token } },)
    .then(response => {
      const blob = new Blob([response.data], { type: 'image/png' });
      return blob;
    })
    .catch(error => {
      console.error('Failed to fetch the image:', error);
      throw error;
    });
};

// ================================================================================
// TSX

type IResultDataType = {
  transformed_image_path: string
  status: string
  face_id: string
} 

function PhotoEditor() {
  const PIC_SIZE:number = 660;
  const MAX_SELECT_FACE:number = 9;
  const ANIM_HALF = { left: '-30%' };
  const ANIM_END = { left: '0%' };
  const ANIM_COOKIE_RUN = { left: '8%' };
  const ANIM_COOKIE_RUN_END = { left: '35%' };

  const navigate = useNavigate();

  const [isEndAnim, setEndAnim]= useState(false);
  const [isProgressShow, setProgressShow] = useState(false);
  const [projectData, setProjectData] = useRecoilState(makeProjectData);
  // const [resultImage, setResultImage] = useRecoilState(resultImageURL);
  const [alertModals, setAlert] = useRecoilState(alertModal);
  const {run} = useAsyncCallback(cropImageToZzu);

  const onProcess = (e: any) => {
    const _checkIdxs:any[] = [];
    
    projectData.data.map((item, index) => {
      if(item.isSelect) _checkIdxs.push(index);
    });

    // ========== process start
    const _data:Object = getIndexsData();
    if(!_data || _checkIdxs.length === 0){
      setAlert({isShow:true, text:<>Please select one or more faces<br/>whose expression you want to change</>});
      return;
    }

    // navigate('/result');
    setAlert({isShow:true, text:<>Use one of your free credits to spread<br/>Kissmas cheer?</>, cancleFn:()=>{}, compFn:()=>{
      setProgressShow(true);
      setEndAnim(false);
      // ==== Making zzu Image
      // LOCAL TEST
      if(IS_LOCAL_TEST){
        setTimeout(() => {
          setProjectData({...projectData, resultImageURL:'https://www.w3schools.com/howto/img_snow.jpg'});
          setEndAnim(true);
        }, 9000);
        return;
      }
      // MAIN PROCESS
      run(projectData.id, _data).then((res:any) => {
        let _resultData: IResultDataType;
        console.log(res);
        try{
          console.log(res.status, res.data.result)
            if(res.status === 200 && res.data.result){
              _resultData = res.data.result[0];
              console.log(API_SERVER_URL+'api/file/'+projectData.id);
              // anim_fifty.stop();
              getImage(API_SERVER_URL+'api/file/'+projectData.id);
            }else{
              setProgressShow(false);
              console.log('!');
            }
        } catch(e){
          console.log(e);
          setProgressShow(false);
        }    

      });

    }});
    
  };

  const getImage = (imageUrl: string) => {
    getImgRun(imageUrl)
      .then((blob:any) => {
        const imageObjectURL = URL.createObjectURL(blob);
        // setResultImage(imageObjectURL);
        setProjectData({...projectData, resultImageURL:imageObjectURL});
        setEndAnim(true);
      })
      .catch((error:any) => {
        console.error('Failed to fetch the image:', error);
        setProgressShow(false);
      });
  }

  const handleAnimationComplete = () => {
    // setEndAnim=true 애니메이션이 끝나고 resultImage가 있으면 result페이지로 이동
    if(isEndAnim){
      setTimeout(() => {
        if(projectData.resultImageURL !== '' && projectData.resultImageURL) navigate(MAIN_ROUTE+'result');
      }, 700);
    }
  }

  const getIndexsData = () => {
    const idxs: number[] = [];
    const divs: { [key: string]: any } = {};

    projectData.data.map((item, index) => {
      if (item.isSelect) {
        idxs.push(index);
        divs[String(index)] = '1';
      }
    });

    return {idxs, divs};
  };

  const selectCropHandler = (idx: number) => {

    const temp = projectData.data.map((item, index) => {
      const _length = getIndexsData().idxs.length;
      if (index === idx) {
        if(!item.isSelect && _length >= MAX_SELECT_FACE){
          setAlert({isShow:true, text:<>You can only select up to 9 people</>});
          return { ...item };
        }
        return { ...item, isSelect: !item.isSelect };
      } else {
        return { ...item };
      }
    });
    
    
    setProjectData({...projectData, data:temp});
  };

  return (
    <Body>
      {/* 초기 화면 */}
      {!projectData.url && <PhotoEditorMainEn/>}

      {/* ========================== Croper로직 통신/얼굴 디텍팅 완료 ========================== */}
      {projectData.url && projectData.data.length > 0 && <>
        <h2>Check Pictures</h2>
        <SelectBox $size={PIC_SIZE} $isDetected>
          <img src={projectData.url} alt="cropImage" />
          {projectData.data.map((item,idx) => (
            <FaceDetectRect
              key={item.id}
              $image = {projectData.url}
              $ratio={projectData.size? projectData.size.width/PIC_SIZE : 0}
              $left={item.l * (projectData.size? PIC_SIZE/projectData.size.width : 0)}
              $top={item.t * (projectData.size? PIC_SIZE/projectData.size.width : 0)}
              $width={(item.r-item.l) * (projectData.size? PIC_SIZE/projectData.size.width : 0)}
              $height={(item.b-item.t) * (projectData.size? PIC_SIZE/projectData.size.width : 0)}
              $isSelect={item.isSelect?? false}
              onClick={() => {selectCropHandler(idx)}}
            >
              <div></div>
            </FaceDetectRect>
          ))}
        </SelectBox>
        <h4>The faces you choose<br/>will have the <GreenText>Kiss</GreenText><RedText>mas</RedText> filter applied to them</h4>
        <GuideInfo>You can create a Christmas kiss with the face in the activated area
          <TooltipBtn className="tooltip1" data-tooltip-place='bottom'>?</TooltipBtn>
        </GuideInfo>
        <Tooltip id="tooltip1" anchorSelect=".tooltip1" place="top" arrowColor='#62A796' style={{backgroundColor:'#62A796', zIndex:10, padding:'5px 20px', borderRadius: 40, fontSize: 20, fontWeight:'bold', display: 'flex', alignItems:'center' }} >
          There are some faces that the AI cannot detect. Please select a new photo
          {/* <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft:5}}>
            <path d="M9.45 6.75H12.15L13.5 10.125L14.85 6.75H17.55L14.85 13.5L17.55 20.25H14.85L13.5 16.875L12.15 20.25H9.45L12.15 13.5L9.45 6.75ZM13.5 0C15.2728 0 17.0283 0.349188 18.6662 1.02763C20.3041 1.70606 21.7924 2.70047 23.0459 3.95406C24.2995 5.20765 25.2939 6.69588 25.9724 8.33377C26.6508 9.97167 27 11.7272 27 13.5C27 17.0804 25.5777 20.5142 23.0459 23.0459C20.5142 25.5777 17.0804 27 13.5 27C11.7272 27 9.97167 26.6508 8.33377 25.9724C6.69588 25.2939 5.20765 24.2995 3.95406 23.0459C1.42232 20.5142 0 17.0804 0 13.5C0 9.91958 1.42232 6.4858 3.95406 3.95406C6.4858 1.42232 9.91958 0 13.5 0ZM13.5 2.7C10.6357 2.7 7.88864 3.83785 5.86325 5.86325C3.83785 7.88864 2.7 10.6357 2.7 13.5C2.7 16.3643 3.83785 19.1114 5.86325 21.1368C7.88864 23.1621 10.6357 24.3 13.5 24.3C16.3643 24.3 19.1114 23.1621 21.1368 21.1368C23.1621 19.1114 24.3 16.3643 24.3 13.5C24.3 10.6357 23.1621 7.88864 21.1368 5.86325C19.1114 3.83785 16.3643 2.7 13.5 2.7Z" fill="white"/>
          </svg> */}
        </Tooltip>
        
        <EditorResultFooterBtnWrapper $isDouble>
          <ImageUploadToCropButton text={'Browse other photos'} />
          <NavLinkItem onClick={onProcess} child={'Complete Selection'} isButton></NavLinkItem>
          {/* <GreenButton whileTap={{ scale: 0.9 }} onClick={onProcess}>선택 완료하기</GreenButton> */}
        </EditorResultFooterBtnWrapper>
    </>}
    
    {/* ========================== Croper로직 완료되었지만 얼굴이 없는경우 ========================== */}
    {projectData.url && projectData.data.length <= 0 && <>
        <h2>Check Pictures</h2>
        <SelectBox $size={PIC_SIZE} $isDetected={false}>
          <img src={projectData.url} alt="cropImage" />
        </SelectBox>
        <FaceDetectFailTitle style={{color:'#bb010b'}}>The AI cannot detect any faces</FaceDetectFailTitle>
        <GuideInfo>Why hasn’t it been captured?
          <TooltipBtn className="tooltip2"  data-tooltip-place='bottom'>?</TooltipBtn>
        </GuideInfo>
        <Tooltip id="tooltip2" anchorSelect=".tooltip2" place="bottom" arrowColor='#62A796' style={{backgroundColor:'#62A796', padding:'10px 20px', borderRadius: 40, zIndex:10, fontSize: 20, fontWeight:'bold' }} >
          <Step>① Looking straight ahead at the camera will give better results</Step>
          <Step>② There should be no objects covering your face</Step>
          <Step>③ The AI cannot recognize faces that are too small</Step>
        </Tooltip>
        <EditorResultFooterBtnWrapper>
          <ImageUploadToCropButton text={'Browse other photos'} isGreenBg />
        </EditorResultFooterBtnWrapper>
    </>}
    
    {/* ========================== ProgressLoading ========================== */}
    { isProgressShow && <ProgressContainer>
      <img src={loading} alt='progress loading' style={{width:500}}/>
        <div>
          <motion.img 
            onAnimationComplete={handleAnimationComplete} 
            animate={isEndAnim ? ANIM_END : ANIM_HALF} 
            transition={{ease: 'easeInOut', duration: isEndAnim ? 1:3}} 
            className='bar'
            src={progressBar} 
            />
          <img src={progressCover} alt='progress_bar cover'/>
        </div>

        <CookieMotionSpan
          animate={isEndAnim ? ANIM_COOKIE_RUN_END : ANIM_COOKIE_RUN} 
          transition={{ease: 'easeInOut', duration: isEndAnim ? 1:3}}
          >
          <img style={{width:150}} src={cookie} alt='cookies running'/>
          <div style={{}}>Processing could take some time<br/>depending on current traffic.</div>
        </CookieMotionSpan>
    </ProgressContainer>}
    
    </Body>
  );
}

export default PhotoEditor;
