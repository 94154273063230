import axios, { AxiosRequestConfig } from "axios"
import handleError from "./handleError"
import { useAsync, useAsyncCallback } from "./hooks"

function _api(
    method: string,
    url: string,
    data: unknown | null = null,
    contentType: string = "",
    responseType: string = "",
    catchErrorCallBack: (error: any) => void = (error: any) => {},
    onUploadProgress: (progressEvent: any) => void = (progressEvent: any) => {}
) {
    const apiData: AxiosRequestConfig<unknown> = {
        url: url,
        method: method,
        onUploadProgress: onUploadProgress,
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Credentials':"false",
        },
    }
    const token: string | null = localStorage.getItem("token")
    
    if (typeof token === "string") apiData.headers = { Authorization: token }
    if (contentType)
        apiData.headers = {
            ...apiData.headers,
            "content-type": contentType,
        }
    if (responseType)
        apiData.headers = {
            ...apiData.headers,
            "responseType": responseType,
        }

    if (typeof data !== "undefined") apiData.data = data
    return axios(apiData)
        .then((res) => {

            if (String(res.status)[0] !== "2") {
                // console.error(res)
                throw res
            }
            return res
        })
        .catch((error) => {
            console.log(error);
            handleError(error)
            catchErrorCallBack(error)
        })
}

function wapperApi(method: string) {
    return (
        url: string,
        data: unknown | null = null,
        contentType: string = "",
        responseType: string = "",
        catchErrorCallBack: (error: any) => void = (error: any) => {},
        onUploadProgress: (progressEvent: any) => void = (
            progressEvent: any
        ) => {}
    ) =>
        _api(
            method,
            url,
            data,
            contentType,
            responseType,
            catchErrorCallBack,
            onUploadProgress
        )
}
const api = {
    get: wapperApi("get"),
    post: wapperApi("post"),
    put: wapperApi("put"),
    delete: wapperApi("delete"),
    patch: wapperApi("patch"),
}
export default api
export { useAsync, useAsyncCallback }
