
import { styled } from 'styled-components';
import { motion } from 'framer-motion';

import ImageUploadToCropButton from '@components/ImageUploadToCropButton';
import { ExampleBox, ExampleBoxThreeContainer, FooterBtnWrapper, GreenText, RedText } from '@/styles/main';
import { BoxVars, BoxVars2, CircleVars, WidthVars } from '@/styles/animAssets';

import sample_no from '@assets/sample_no.png';
import sample_ok from '@assets/sample_ok.png';

import sample_ok1 from '@assets/sample-good1.png';
import sample_ok2 from '@assets/sample-good2.png';
import sample_ok3 from '@assets/sample-good3.png';
import sample_no1 from '@assets/sample-bad1.png';
import sample_no2 from '@assets/sample-bad2.png';
import sample_no3 from '@assets/sample-bad3.png';
// ================================================================================
// styled

const GuideInfo = styled(motion.div)`
  -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust : none;  /* IE */
  -moz-text-size-adjust : none;  /* 파이어폭스 */
  -o-text-size-adjust : none;  /* 오페라 구버전 */

  &> p {font-weight: 400 !important; font-size: 23px; margin:2px 0;}
  i { font-style: normal; color:#bb010b }
  
`;

const BgUnderText = styled.span`

  position: relative; display: inline-block; padding: 0 2px; overflow: hidden; top:8px; position: relative;
  & > a { z-index:1; position:relative; font-size: 23px !important; font-weight: 700;}
  & > span {background-color:#23856D; font-size:23px; position:absolute; width:100%; left:0; height:7px; bottom:3px; z-index:0;}
`;

const Guide = styled.p`
  font-weight: 500; font-size: 30px; line-height: 45px;
`;

const ExampleBoxItem = styled(ExampleBox)<{$isOkay:Boolean}>`
  position: relative;
   /* border: 4px solid ${({$isOkay})=> $isOkay? `#006f57`:`#BB0B0B`}; */
  & > img { position:absolute; bottom:3px; right:3px;}
`
// ================================================================================

// ================================================================================

function PhotoEditorMain() {
    const bgUnderLine = (text: string) => {
        return <BgUnderText>
          <motion.span variants={WidthVars}></motion.span>
          <motion.a variants={CircleVars}>{text}</motion.a>
        </BgUnderText>
      }
  return (
    <motion.div>
        <h2>Select Photo</h2>
        <Guide>
        Everyone in the photo that the AI can detect
        <br/>will have a merry Christmas!
        </Guide>
        <motion.div variants={BoxVars} initial="start" animate="end">
          <ExampleBoxThreeContainer>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok1}><img src={sample_ok} alt='successful_example_pic1'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok2}><img src={sample_ok} alt='successful_example_pic2'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay variants={CircleVars} $imgUrl={sample_ok3}><img src={sample_ok} alt='successful_example_pic3'/></ExampleBoxItem>
          </ExampleBoxThreeContainer>
          <ExampleBoxThreeContainer>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no1}><img src={sample_no} alt='ineffectively_example_pic1'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no2}><img src={sample_no} alt='ineffectively_example_pic2'/></ExampleBoxItem>
            <ExampleBoxItem $isOkay={false} variants={CircleVars} $imgUrl={sample_no3}><img src={sample_no} alt='ineffectively_example_pic3'/></ExampleBoxItem>
          </ExampleBoxThreeContainer>
        </motion.div>
        <GuideInfo variants={BoxVars2} initial="start" animate="end">
            <p>{bgUnderLine('Looking straight ahead')} at the camera will give better results</p>
            <p>There should be {bgUnderLine('no objects covering your face')}</p>
            <p>The AI <i>cannot</i> recognize {bgUnderLine('faces that are too small')}</p>
            <p>Due to the nature of the AI, {bgUnderLine('some unfortunate results may occur')}</p>
        </GuideInfo>
        <FooterBtnWrapper>
          <ImageUploadToCropButton text={"I'll look for a picture"} isGreenBg />
          {/* <a>*크리스마스 키스 만들기는 계정당 5번만 진행할 수 있어요</a> */}
        </FooterBtnWrapper>
      </motion.div>
  );
}

export default PhotoEditorMain;